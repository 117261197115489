// import { isUndefined } from 'lodash';
import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import RecruitmentListView from './pages/recruitments/ListView';
import RecruitmentEdit from './pages/recruitments/Edit';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);
// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const NoContentFound = Loadable(lazy(() => import('./pages/NoContentFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('./pages/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('./pages/authentication/ResetPassword')));

// Dashboard pages
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));

// Blog Pages
const BlogOverview = Loadable(lazy(() => import('./pages/blog/BlogOverview')));
const BlogAddView = Loadable(lazy(() => import('./pages/blog/BlogAddView')));
const BlogEditView = Loadable(lazy(() => import('./pages/blog/BlogEditView')));
const BlogCategoryAddView = Loadable(lazy(() => import('./pages/blog/BlogCategoryAddView')));
const BlogCategoryEditView = Loadable(lazy(() => import('./pages/blog/BlogCategoryEditView')));
const BlogCategoryView = Loadable(lazy(() => import('./pages/blog/BlogCategoryOverview')));
const BlogTagView = Loadable(lazy(() => import('./pages/blog/BlogTagOverview')));
const BlogPreview = Loadable(lazy(() => import('./pages/blog/BlogPreview')));
const TagAddView = Loadable(lazy(() => import('./pages/blog/TagAddView')));
const TagEditView = Loadable(lazy(() => import('./pages/blog/TagEditView')));
// Client Pages
const ClientOverview = Loadable(lazy(() => import('./pages/client/ClientOverview')));
const ClientSingleView = Loadable(lazy(() => import('./pages/client/ClientSingleView')));
const MessagesSingleView = Loadable(lazy(() => import('./pages/messages/MessagesSingleOverview')));

const ClientSingleErrorView = Loadable(lazy(() => import('./pages/client/ClientSingleErrorView')));
const ClientEditView = Loadable(lazy(() => import('./pages/client/ClientEditView')));
const ClientAddView = Loadable(lazy(() => import('./pages/client/ClientAddView')));

const SettingView = Loadable(lazy(() => import('./pages/setting/SettingView')));
// Change Logs
const ChangeLogs = Loadable(lazy(() => import('./pages/ChangeLogs')));
const Messages = Loadable(lazy(() => import('./pages/messages/MessagesOverviewAll')));

// Search
const SearchOverview = Loadable(lazy(() => import('./pages/search/SearchOverview')));

const routes: RouteObject[] = [
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Analytics />
      },
    ]
  },
  {
    path: '/admin',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/admin/users',
        children: [
          {
            path: '',
            element: <ClientOverview />
          },
          {
            path: '/admin/users/create',
            element: <ClientAddView />
          },
          {
            path: '/admin/users/:clientId',
            element: <ClientSingleView />
          },
          {
            path: '/admin/users/edit/:clientId',
            element: <ClientEditView />
          },
          {
            path: '/admin/users/error_devices/:clientId',
            element: <ClientSingleErrorView />
          },
        ]
      },
      {
        path: '/admin/blogs',
        children: [
          {
            path: '',
            element: <BlogOverview />
          },
          {
            path: '/admin/blogs/create',
            element: <BlogAddView />
          },
          {
            path: '/admin/blogs/:id',
            element: <BlogEditView />
          },
          {
            path: '/admin/blogs/preview/:id',
            element: <BlogPreview />
          },
        ]
      },
      {
        path: '/admin/ChangeLogs',
        element: <ChangeLogs />
      },
      {
        path: '/admin/recruitment-emails',
        element: <RecruitmentListView />
      },
      {
        path: '/admin/recruitment-emails/:recruitmentId',
        element: <RecruitmentEdit />
      },
      {
        path: '/admin/messages',
        element: <Messages />
      },
      {
        path: '/admin/messages/:email',
        element: <Messages />
      },
      {
        path: '/admin/messages/singleMessage/:messageId',
        element: <MessagesSingleView />
      },
      {
        path: '/admin/create/categories',
        element: <BlogCategoryAddView />
      },
      {
        path: '/admin/category/:id',
        element: <BlogCategoryEditView />
      },
      {
        path: '/admin/categories',
        element: <BlogCategoryView />
      },
      {
        path: '/admin/tags',
        element: <BlogTagView />
      },
      {
        path: '/admin/create/tag',
        element: <TagAddView />
      },
      {
        path: '/admin/tag/:id',
        element: <TagEditView />
      },
      {
        path: 'search',
        element: <SearchOverview />
      },
      {
        path: '/admin/setting',
        element: <SettingView />,
      },
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'forgot-password',
        element: (
          <GuestGuard>
            <ForgotPassword />
          </GuestGuard>
        )
      },
      {
        path: 'reset-password/:id',
        element: (
          <GuestGuard>
            <ResetPassword />
          </GuestGuard>
        )
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: 'not-found',
        element: <NoContentFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
