import type { ChangeEvent, FC, MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  Checkbox, CircularProgress, Divider,
  InputAdornment, makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@material-ui/core';

import { isUndefined } from 'lodash';
import { messagesApi } from 'src/api/messagesApi';
import SearchIcon from 'src/icons/Search';
// import capitalize from 'src/utils/capitalize';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../Scrollbar';
// import moment from 'moment';
// import logger from 'src/utils/logger';
import moment from 'moment';
import toast from 'react-hot-toast';
import logger from 'src/utils/logger';
import messagesEllipse from 'src/utils/messagesEllipse';
import DeleteModal from './DeleteModal';
import RecruitmentsReadModel from './RecruitmentsReadModel';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .css-1frtkag-MuiButtonBase-root-MuiButton-root': {
      height: '43px',
      borderRadius: '4px !important'
    },
    '& .css-8xplcm-MuiButton-label': {
      lineHeight: '23px',
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Axiforma-regular',
    },
    '& .css-1mpkxyq-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
      fontSize: '14px',
      fontFamily: 'Axiforma-regular',
      color: '#263238',
    },
    '& .css-dasrns-MuiInputBase-input-MuiOutlinedInput-input': {
      fontSize: '14px',
      fontFamily: 'Axiforma-regular',
      color: '#263238',
    },
    '& .css-1iu6g9y-MuiPaper-root-MuiCard-root': {
      borderRadius: '8px'
    },
    '& .css-s4vs68-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important'
    },
    '& .css-wb57ya-MuiFormControl-root-MuiTextField-root': {
      minWidth: '198px'
    },
    '& .css-1wm406u-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important'
    },
    '& .css-lmeri-MuiButtonBase-root-MuiButton-root': {
      borderRadius: '4px !important'
    },
    borderRadius: '4px !important',
    '& .MuiTableCell-root': {
      paddingTop: 8.5,
      paddingBottom: 8.5,
    },
    '& .MuiToolbar-root': {
      minHeight: 60,
    },
    '& .css-15fev7r-MuiGrid-root': {
      display: 'block'
    },
    '& .MuiNativeSelect-select': {
      padding: '9.5px 14px',
      color: '#263238',
      fontWeight: 500,
      '& option': {
        color: '#263238',
        fontWeight: 400,
      },
    },
    '& .MuiTableRow-root': {
      cursor: 'pointer',
    },
  },
  headerButtonsName: {
    lineHeight: '23px',
    fontFamily: 'Axiforma-regular',
    fontSize: '14px',
    fontWeight: 500,
    color: '#263238',
    textTransform: 'uppercase'
  },
  addNewClient: {
    textTransform: 'uppercase',
    borderRadius: 4,
    border: `1px solid ${theme.palette.secondary.main}`,
    boxShadow: 'none',
    '& + &': {
      marginLeft: theme.spacing(1)
    },
    '&:hover': {
      color: theme.palette.secondary.main,
      background: theme.palette.background.default,
    }
  },
  filterButtonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    right: 36,
    [theme.breakpoints.down('sm')]: {
      right: 0,
    }
  },
  tebaleData: {
    textAlign: 'left'
  },
  resetFiltersButton: {
    textTransform: 'uppercase',
    borderRadius: 4,
    height: 42,
    width: 220,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.secondary.main}`,
    '& + &': {
      marginLeft: theme.spacing(1)
    },
    '&:hover': {
      color: theme.palette.secondary.main,
      background: theme.palette.background.default,
    }
  },
  deleteButton: {
    textTransform: 'uppercase',
    borderRadius: 4,
    border: '1px solid #B0BEC5',
    background: 'white !important',
    color: '#172b4d',
    width: '130px',
    marginRight: '3%',
    boxShadow: 'none',
    padding: theme.spacing(1),
    '& path': {
      fill: '#172b4d',
    },
    '& + &': {
      marginLeft: theme.spacing(1)
    },
  },
  card: {
    borderRadius: '5px',
  },
  tab: {
    paddingTop: 35,
    paddingBottom: 15,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 5,
      paddingBottom: 0,
    },
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    paddingRight: 37,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      flexDirection: 'column',
    }
  },
  searchBoxWrapper: {},
  searchBox: {
    padding: 10,
    borderRadius: 4,
    height: 42,
    width: 278,
  },
  statusOnline: {
    background: '#daf6de',
    width: '50%',
    padding: '4px',
    borderRadius: '4px',
    textAlign: 'center'
  },
  statusTableCellOnline: {
    color: '#4CAF50',
    fontWeight: 500,
    fontStyle: 'normal',
    fontFamily: 'Axiforma-regular',
  },
  status: {
    marginLeft: '15%',
    marginRight: '15%',
    // width: '100%',
    padding: '5px 4px 4px',
    borderRadius: '4px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  activeStatus: {
    backgroundColor: 'rgb(232, 245, 233)',
    color: 'rgb(76, 175, 80)',
  },
  deActiveStatus: {
    backgroundColor: 'rgba(255, 87, 86, .2)',
    color: 'rgb(255, 87, 86)',
  },
  statusWrapper: {
    color: '#F57C00',
    fontWeight: 500,
    fontStyle: 'normal',
    fontFamily: 'Axiforma-regular',
  },
  viewBtn: {
    width: 90,
    borderRadius: '4px',
    fontSize: '14px',
    color: '#A1A1A1',
    fontWeight: 500,
    fontStyle: 'normal',
    fontFamily: 'Axiforma-regular',
    padding: '6px 10px',
    border: '1.8px solid #A1A1A1',
    textAlign: 'center',
    boxShadow: 'none'
  },
  viewTableColumnHeader: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    justifyContent: 'flex-start',
    width: '100%',
  },
  viewIcon: {
    marginRight: '10px'
  },
  filterWrapper: {
    display: 'flex',
    // padding: '20px 0px 25px 20px',
  },
  filterMain: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    alignItems: 'center',
    padding: '10px 25px 10px 0px',
  },
  dropDownWrapper: {
    display: 'flex',
    width: '15rem',
    borderRadius: '4px',
    marginRight: '0px !important',
  },
  dropDownText: {
    marginRight: 60,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFamily: 'Axiforma-regular',
    fontSize: '14px',
    textAlign: 'center',
    display: 'inline-block',
  },
  dropDown: {
    width: '100%',
    '&:active': {
      outline: 'none',
      cursor: 'pointer',
    },
    padding: '8px 14px !important',
    '& + &': {
      marginLeft: theme.spacing(1),
      borderColor: 'blue',
    },
  },
  dropDownIcon: {

  },
  select: {
    marginRight: '10px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  selectBorderRadius: {
    borderRadius: 4,
  },
  firstTd: {
    // paddingLeft: 45,
  },
  lastTd: {
    paddingRight: 45,
  },
  tablePagination: {
    paddingRight: '27px !important',
  },
  noRecordFound: {
    textAlign: 'center',
  },
  activeDateRange: {
    display: 'block',
    position: 'absolute',
    boxShadow:
      '0px 0px 0px rgba(63, 63, 68, 0.05), 0px 0px 4px rgba(63, 63, 68, 0.2)',
    zIndex: 1
  },
  deActiveDateRange: {
    display: 'none !important'
  },
  dateRangeSelection: {
    position: 'relative',
    top: '-8rem',
    border: '1px solid #eee',
    paddingTop: 35,
    background: 'white',
    margin: '0 auto',
    textAlign: 'center',
    boxShadow:
      '0px 0px 0px rgba(63, 63, 68, 0.05), 0px 0px 4px rgba(63, 63, 68, 0.2)',
    [theme.breakpoints.down('sm')]: {
      position: 'initial',
      border: 'none',
      margin: 'unset',
      boxShadow: 'none',
    }
  },
  textField: {
    '& .MuiInputBase-input': {
      padding: '10px 20px',
    },
  },
  searchIcon: {
    color: '#263238'
  },
  loadingCircle: {
    color: '#DF1683',
    width: '20px !important',
    height: '20px !important',
    marginLeft: 5,
    marginRight: 5
  },
  tableCell: {
    cursor: 'pointer',
    height: '64.5px',
    alignItems: 'center',
    textAlign: 'center'
  },
  chevronIcon: {
    position: 'relative',
    top: 6,
    left: 20,
    width: 24
  },
  isSortedColumn: {
    position: 'relative',
    top: '-4px',
  },
  headerMainDev: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2%',
    }
  }
}));

const RecruitmentMessagesListTable: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);

  const [selectedMessage, setSelectedMessage] = useState<number[]>([]);
  const [messagess, setMessages] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  // const [resetSearching, setResetSearching] = useState<boolean>(false);
  const [selectAllDev, setSelectAllDev] = useState<boolean>(false);

  const [openDeleteModalRead, setOpenDeleteModalRead] = useState<boolean>(false);
  let searchByEmail = window?.location?.pathname?.split('/').pop();
  const [search, setSearch] = useState<string>('');
  const [sorting, setSorting] = useState<any>('desc');
  // const [searchingMail, setSearchingMail] = useState<string>('');
  const [total, setTotalCount] = useState<number>(0);

  const getAll = async () => {
    try {
      const filter = {
        page,
        limit,
        search,
        orderBy: {
          created_at: sorting
        },
      };
      const res = await messagesApi.getAllRecruitments(filter);
      console.log('get-all-recruitments: ', res);
      setMessages(res?.data);
      setTotalCount(res?.totalCount);
      setIsSubmitting(false);
    } catch (err) {
      logger(err, 'error');
      setIsSubmitting(false);
      setTotalCount(0);
    }
  };
  useEffect(() => {
    getAll();
  }, [page, limit, isSubmitting, search, sorting]);

  useEffect(() => {
    if (searchByEmail === 'messages') {
      setSearch('');
      setIsSubmitting(true);
    }
  }, [searchByEmail]);

  useEffect(() => {
    setIsSubmitting(true);
  }, [sorting]);

  const handleSelectAllMessages = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedMessage(event.target.checked
      ? messagess?.map((message) => message?.id)
      : []);
  };
  const handleSelectOneMessage = (
    messageId: number
  ): void => {
    if (!selectedMessage.includes(messageId)) {
      setSelectedMessage((prevSelected) => [...prevSelected, messageId]);
    } else {
      setSelectedMessage((prevSelected) => prevSelected.filter((id) => id !== messageId));
    }
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
    setIsSubmitting(true);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
    setIsSubmitting(true);
  };

  const selectedSomeMessages = selectedMessage.length > 0
    && selectedMessage.length < messagess?.length;
  const selectedAllCategories = messagess?.length > 0 ? selectedMessage.length === messagess?.length : false;

  const navigateToOneMessage = (e, messageId: string): void => {
    if (isUndefined(e.target.value)) {
      navigate(`/admin/recruitment-emails/${messageId}`);
    }
  };

  const handleSearch = (event) => {
    if (event?.type === 'click') {
      setIsSubmitting(true);
    }
    event.persist();
    if (event.key === 'Enter') {
      setIsSubmitting(true);
    }
  };
  // const openModal = () => {
  //   if (selectedMessage?.length > 0) {
  //     setOpenDeleteModal(true);
  //   } else {
  //     setOpenDeleteModal(false);
  //     const msg = t('clients_bulk_no_user_is_selected_error_message');
  //     toast.dismiss();
  //     toast.error(<div style={{ fontFamily: 'Axiforma-regular' }}>{msg}</div>, {
  //       duration: 10000,
  //     });
  //   }
  // };
  const openModalRead = () => {
    if (selectedMessage?.length > 0) {
      setOpenDeleteModalRead(true);
    } else {
      setOpenDeleteModalRead(false);
      const msg = t('clients_bulk_no_user_is_selected_error_message');
      toast.dismiss();
      toast.error(<div style={{ fontFamily: 'Axiforma-regular' }}>{msg}</div>, {
        duration: 10000,
      });
    }
  };
  return (
    <div className={classes.root}>
      <Card>
        <Scrollbar>
          <div className={classes.headerMainDev}>
            <div>
              {/* jdj */}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '2%', paddingLeft: '2%', paddingRight: '2%' }}>
              {selectedMessage?.length > 0 && (
                <div style={{ display: 'flex', marginRight: '1.5%' }}>
                  <div
                    className={classes.tab}
                    style={{ marginRight: '2%' }}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      className={classes.deleteButton}
                      onClick={() => openModalRead()}
                    >
                      <div className={classes.headerButtonsName}>
                        MARK AS READ
                      </div>
                    </Button>
                  </div>

                  {/* <div
                    className={classes.tab}
                    style={{ marginRight: '1%' }}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      className={classes.deleteButton}
                      onClick={() => openModal()}
                    >
                      <div className={classes.headerButtonsName}>
                        {t('clients_filter_delete_button')}
                      </div>
                    </Button>
                  </div> */}
                </div>
              )}
              <div
                className={classes.tab}
                style={{ marginRight: '1%' }}
              >
                <TextField
                  InputProps={{
                    classes: {
                      root: classes.textField
                    },
                  }}
                  fullWidth
                  name="sorting"
                  required
                  onChange={(e) => setSorting(e.target.value)}
                  select
                  SelectProps={{ native: true }}
                  defaultValue="desc"
                  variant="outlined"
                >
                  <option value="desc">
                    SORT BY : NEWEST
                  </option>
                  <option value="asc">
                    SORT BY : OLDEST
                  </option>
                </TextField>
              </div>
              <div
                className={classes.tab}
                style={{ marginRight: '1%', paddingRight: '1%' }}
              >
                <TextField
                  size="small"
                  InputProps={{
                    classes: {
                      root: classes.searchBox
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                      >
                        {isSubmitting ? (<CircularProgress className={classes.loadingCircle} />) : <SearchIcon className={classes.searchIcon} />}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                      >
                        <div style={{ marginRight: '-10px' }}>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={handleSearch}
                          >
                            GO
                          </Button>
                        </div>
                      </InputAdornment>
                    )
                  }}
                  onKeyPress={handleSearch}
                  onInput={handleSearch}
                  onChange={(e) => { setSearch(e.target.value); searchByEmail = undefined; }}
                  placeholder={t('clients_filter_search')}
                  value={search}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        </Scrollbar>
        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            <div
              className={classes.firstTd}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Checkbox
                checked={selectedAllCategories}
                color="primary"
                indeterminate={selectedSomeMessages}
                onChange={handleSelectAllMessages}
                onMouseEnter={() => setSelectAllDev(true)}
                onMouseLeave={() => setSelectAllDev(false)}
              />
              <div style={{ display: selectAllDev ? 'block' : 'none' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: '4px', fontFamily: 'axiforma' }}>Select</div>
                  <div style={{ fontFamily: 'axiforma' }}>all</div>
                </div>
              </div>

            </div>
            <Divider />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell width="250px">Name</TableCell>
                  <TableCell width="250px">Email</TableCell>
                  <TableCell width="150px">Phone No.</TableCell>
                  <TableCell sx={{ width: '100px' }}>Message</TableCell>
                  <TableCell sx={{ width: '100px' }}>Job Title</TableCell>
                  <TableCell width="120px">Apply Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messagess?.map((message) => {
                  const isMessageSelected = selectedMessage.includes(message?.id);

                  return (
                    <TableRow
                      hover
                      key={message?.id}
                      style={{ backgroundColor: message?.is_read ? 'inherit' : 'rgba(194, 32, 39, 0.1)' }}
                      selected={isMessageSelected}
                      onClick={(e) => navigateToOneMessage(e, message?.id)}
                    >
                      <TableCell
                        padding="checkbox"
                        className={classes.firstTd}
                      >
                        <Checkbox
                          checked={isMessageSelected}
                          color="primary"
                          onChange={() => handleSelectOneMessage(
                            message?.id
                          )}
                          value={isMessageSelected}
                        />
                      </TableCell>
                      <TableCell>
                        <div style={{ display: 'flex' }}>
                          {message?.first_name}
                          <div style={{ marginRight: '4%' }} />
                          {message?.last_name}
                        </div>
                      </TableCell>
                      <TableCell>
                        {message?.email}
                      </TableCell>
                      <TableCell>
                        {message?.phone_number}
                      </TableCell>
                      <TableCell>
                        {messagesEllipse(message?.message)}
                      </TableCell>
                      <TableCell>
                        {message?.name ? message?.name : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {message?.created_at ? moment(message?.created_at).format('MMM-D-YY') : 'N/A'}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {messagess?.length === 0 && (
                  <TableRow
                    key="aa-12-2d-ac-xza"
                  >
                    <TableCell
                      colSpan={6}
                      className={classes.noRecordFound}
                    >
                      {t('clients_no_record_found')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
        <DeleteModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          clientsList={selectedMessage}
        />
        <RecruitmentsReadModel
          openDeleteModal={openDeleteModalRead}
          setOpenDeleteModal={setOpenDeleteModalRead}
          clientsList={selectedMessage}
          setClientsList={setSelectedMessage}
        />
        <TablePagination
          className={classes.tablePagination}
          labelRowsPerPage={t('clients_table_row_per_page')}
          component="div"
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </Card>
    </div>
  );
};

export default RecruitmentMessagesListTable;
