import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
// import { matchPath } from 'react-router-dom';
import { List, ListSubheader } from '@material-ui/core';
import type { ListProps } from '@material-ui/core';
import NavItem from './NavItem';
import getNthCharacterPosition from 'src/utils/getNthCharacterPosition';

interface Item {
  path?: string;
  icon?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  title: string;
  viewable: boolean;
  counter?: any
}

interface NavSectionProps extends ListProps {
  items: Item[];
  pathname: string;
}

const renderNavItems = ({
  depth = 0,
  items,
  pathname
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}): JSX.Element => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth
      }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth
}: {
  acc: JSX.Element[];
  pathname: string;
  item: Item;
  depth: number;
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;
  const index = getNthCharacterPosition(window.location.pathname, '/', 3);
  const pathName = window.location.pathname?.substring(0, index);
  let partialMatch = pathName?.indexOf(item?.path) !== -1;
  if (item?.viewable) {
    if (item.children) {
      partialMatch = item?.path?.indexOf(pathName) !== -1;
      if (!partialMatch) {
        item?.children?.map((children) => {
          if (pathName?.indexOf(children?.path) !== -1) {
            partialMatch = pathName?.indexOf(children?.path) !== -1;
          }
          return children;
        });
      }
      acc.push(
        <NavItem
          active={partialMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={partialMatch}
          path={item.path}
          title={item.title}
          counter={item?.counter}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item.children,
            pathname
          })}
        </NavItem>
      );
    } else {
      partialMatch = pathName?.indexOf(item?.path) !== -1;
      acc.push(
        <NavItem
          active={partialMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          title={item.title}
          counter={item?.counter}
        />
      );
    }
  }

  return acc;
};

const NavSection: FC<NavSectionProps> = (props) => {
  const {
    items,
    pathname,
    ...other
  } = props;

  return (
    <List
      subheader={(
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.secondary',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          {/* {title} */}
        </ListSubheader>
      )}
      {...other}
    >
      {renderNavItems({
        items,
        pathname
      })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
};

export default NavSection;
