export const nl = {
  login_sign_in: 'Sign In',
  login_email_address: 'Email Address',
  login_password: 'Password',
  login_forget_password: 'Forgot Password?',
  login_credentials_error: 'Please check your email and password',
  login_api_error: 'Internal Server Error',
  internal_server_error: 'Internal Server Error',
  /* Forget Password */
  forget_password_title: 'Forgot Password',
  forget_password_email_address: 'Email Address',
  forget_password_button: 'Send Reset Password Link',
  forget_password_login: 'Login',
  forgot_password_form_success:
           'Password Reset Email is forwarded. Please check your inbox.',
  forgot_password_subject: 'IT22 Admin Panel - Forgot Password',
  temporary_password_subject: 'IT22 Admin Panel - New Account Created',
  forgot_password_required_form_error: 'Email is required',
  forgot_password_valid_form_error: 'Must be a valid email',
  /* END Forget Password */
  /** Reset Password */
  reset_password_title: 'Reset Password',
  reset_password_new_password: 'New Password',
  reset_password_confirm_password: 'Confirm Password',
  reset_password_new_password_required_form_error:
           'New Password is required',
  reset_password_confirm_password_required_form_error:
           'Confirm Password is required',
  reset_password_passwords_not_matching: "Passwords don't match",
  reset_password_new_password_form_error:
           'Password must contain atleast one alphabet of uppercase, lowercase, number and special character',
  reset_password_button: 'Reset Password',
  reset_password_form_success: 'Your password is updated successfully.',
  /** End Reset Password */
  /* DASHBOARD */
  dashboard_title: 'Dashboard',
  dashboard_amount_of_devices: 'AMOUNT OF DEVICES',
  dashboard_amount_of_devices_week: 'AMOUNT OF DEVICES LAST WEEK',
  dashboard_amount_of_devices_error: 'AMOUNT OF DEVICES WITH ERRORS',
  dashboard_temperature: 'RETOUR TEMPERATURE IN CELCIUS',
  dashboard_device_status: 'Device Analytics',
  dashboard_routing_temperature: 'Routing Temperature change/week',
  dashboard_routing_temperature_hr: 'Hr',
  dashboard_error_devices: 'Error Devices',
  dashboard_error: 'ERROR',
  dashboard_online: 'ONLINE',
  dashboard_offline: 'OFFLINE',
  'dashboard organic': 'Organic',
  'dashboard referal': 'Referral',
  dashboard_see_all: 'SEE ALL',
  /* END DASHBOARD */
  /* Sidebar */
  date_range: 'Date Range',
  sidebar_dashboard: 'Dashboard',
  sidebar_setting_heading: 'Dashboard',
  sidebar_setting: 'Admin Settings',
  sidebar_clients_overview: 'Clients Overview',
  sidebar_clients: 'Clients',
  sidebar_sub_clients: 'Sub Clients',
  sidebar_devices_overview: 'Devices Overview',
  sidebar_devices: 'Devices',
  sidebar_projects: 'Projects',
  sidebar_presets: 'Presets',
  sidebar_fortes_users: 'Admins',
  sidebar_fortes_settings: 'Settings',
  sidebar_audit: 'Audit',
  sidebar_reports: 'Reports',
  sidebar_total_devices: 'Devices Total',
  sidebar_online_devices: 'Devices Online',
  sidebar_offline_devices: 'Devices Offline',
  sidebar_error_devices: 'Devices Error',
  sidebar_change_logs: 'Change Logs',
  image_upload_success: 'Image Updated Successfully!',
  image_upload_file_type_error: 'Only JPEG and PNG files are allowed!',
  image_upload_file_size_error: 'File size should be less than 5 MB',
  image_uploading_text: 'Uploading',
  /* END Sidebar */
  /* Navbar */
  navbar_search: 'Search',
  navbar_notification: 'Notification',
  navbar_no_notification: 'There are no Notifications',
  navbar_profile: 'Profile',
  navbar_setting: 'Settings',
  navbar_logout: 'Logout',
  /* END Navbar */
  active_label: 'ACTIVE',
  deactive_label: 'INACTIVE',
  please_fix_form_errors: 'Please fix form errors',
  /* Clients */
  clients: 'Clients',
  clients_all: 'ALL',
  clients_active: 'ACTIVE',
  clients_inactive: 'INACTIVE',
  clients_add_new: 'Add New',
  clients_filter_search: 'Search',
  clients_sort_by_filter_label: 'Sort by',
  clients_sort_by_value_1: 'Date(newest)',
  clients_sort_by_value_2: 'Date(oldest)',
  clients_sort_by_value_3: 'Alphabetical',
  clients_country_filter_label: 'Country',
  clients_filter_reset_button: 'Reset',
  clients_filter_delete_button: 'Delete',
  clients_table_name: 'Name',
  clients_table_country: 'Country',
  clients_table_devices: 'Devices',
  clients_table_added: 'Added',
  clients_table_status: 'Status',
  clients_table_row_per_page: 'Rows Per Page',
  blogs_table_row_per_page: 'Rows Per Page',
  clients_bread_crumb_main_title: 'Clients',
  add_clients_bread_crumb_first_link: 'Add Client',
  add_clients_form_header: 'Add Client',
  edit_clients_bread_crumb_first_link: 'Edit Client',
  edit_clients_form_header: 'Edit Client',
  clients_form_client_name_field: 'Name',
  clients_form_email_field: 'Email Address',
  clients_form_user_field: 'User',
  clients_form_city_field: 'City',
  clients_form_country_field: 'Country',
  clients_form_allow_customization_field: 'Allow Customization',
  clients_form_add_client_button: 'Save Changes',
  clients_form_edit_client_button: 'Save Changes',
  clients_form_cancel_button: 'Discard',
  tag_cancel_button: 'CANCEL',
  tag_submit_button: 'ADD TO LIST',
  clients_form_submitting_client_button: 'Submitting...',
  clients_name_required_form_error: 'Name is required',
  clients_email_required_form_error: 'Email is required',
  clients_email_valid_form_error: 'Must be a valid email',
  clients_city_required_form_error: 'City is required',
  clients_country_required_form_error: 'Country is required',
  clients_add_form_success: 'Client created successfully!',
  categories_add_form_success: 'Category created successfully!',

  clients_edit_form_success: 'Client updated successfully!',
  clients_form_api_error: 'Internal Server Error',
  clients_form_email_error: 'Email already exists',
  clients_no_record_found: 'No Clients Found',
  tags_no_record_found: 'No Record(s) Found',
  client_deactivated_message: 'Client deactivated successfully',
  client_activated_message: 'Client Activated successfully',
  clients_bulk_no_user_is_selected_error_message:
           'Please select records to De-activate',
  clients_bulk_deactivated_message:
           '{{ USERS_COUNT }} Client(s) are deactivated successfully',
  tag_bulk_deactivated_message:
           '{{ USERS_COUNT }} Tag(s) are deactivated successfully',
  blog_categories_bulk_deactivated_message:
           '{{ USERS_COUNT }} Blog(s) are deactivated successfully',
  messages_bulk_deactivated_message:
           '{{ USERS_COUNT }} Message(s) are deactivated successfully',
  messages_bulk_read_message:
           '{{ USERS_COUNT }} Message(s) are read successfully',
  client_single_stats_table_title: 'Status',
  /* END Clients */
  client_single_first_bread_crumb: 'Clients',
  client_single_second_bread_crumb: 'View Client',
  client_single_country_label: 'Country',
  client_single_devices_label: 'Devices',
  client_single_edit_button: 'Edit',
  client_single_users_tab: 'Users',
  client_single_apis_tab: 'API Keys',
  client_single_customization_tab: 'Customization',
  client_single_customization_tab_logo: 'Logo',
  client_single_customization_tab_primary_color: 'Primary Color',
  client_single_customization_tab_secondary_color: 'Secondary Color',
  client_single_customization_tab_submit_button: 'Save Changes',
  client_single_customization_color_schema_success:
           'Color Schema is updated successfully.',
  client_single_filter_search: 'Search',
  client_single_add_new_user_button: 'Add User',
  client_single_add_new_api_key_button: 'Add API Key',
  client_single_role_id_filter: 'Role',
  client_single_users_search: 'Search',
  client_single_filter_reset_button: 'Reset',
  client_single_users_all: 'ALL',
  client_single_users_active: 'ACTIVE',
  client_single_users_inactive: 'INACTIVE',
  client_single_filter_delete_button: 'Delete',
  client_single_users_table_name: 'Name',
  client_single_users_table_address: 'Address',
  client_single_users_table_role_id: 'Role',
  client_single_users_table_status: 'Status',
  client_single_users_table_added: 'Added',
  client_single_users_table_no_record_found: 'No Record(s) Found',
  client_single_table_row_per_page: 'Rows Per Page',
  client_single_stats_online_devices_label: 'Online Devices',
  client_single_stats_error_devices_label: 'Error Devices',
  client_single_no_user_selected_for_de_activation_message:
           'Please select records to De-activate',
  /* END Clients Single */
  /* Sub Clients */
  sub_clients: 'Sub Clients',
  sub_clients_all: 'ALL',
  sub_clients_active: 'ACTIVE',
  sub_clients_inactive: 'INACTIVE',
  sub_clients_add_new: 'Add New',
  sub_clients_filter_search: 'Search',
  sub_clients_sort_by_filter_label: 'Sort by',
  sub_clients_sort_by_value_1: 'Date(newest)',
  sub_clients_sort_by_value_2: 'Date(oldest)',
  sub_clients_sort_by_value_3: 'Alphabetical',
  sub_clients_country_filter_label: 'Country',
  sub_clients_filter_reset_button: 'Reset',
  sub_clients_filter_delete_button: 'Delete',
  sub_clients_table_name: 'Name',
  sub_clients_table_country: 'Country',
  sub_clients_table_devices: 'Devices',
  sub_clients_table_added: 'Added',
  sub_clients_table_status: 'Status',
  sub_clients_table_row_per_page: 'Rows Per Page',
  sub_clients_bread_crumb_main_title: 'Sub Clients',
  add_sub_clients_bread_crumb_first_link: 'Add Sub Client',
  add_sub_clients_form_header: 'Add Sub Client',
  edit_sub_clients_bread_crumb_first_link: 'Edit Sub Client',
  edit_sub_clients_form_header: 'Edit Sub Client',
  sub_clients_form_sub_client_name_field: 'Name',
  sub_clients_form_email_field: 'Email Address',
  sub_clients_form_client_field: 'Client',
  sub_clients_form_user_field: 'User',
  sub_clients_form_city_field: 'City',
  sub_clients_form_country_field: 'Country',
  sub_clients_form_add_sub_client_button: 'Save Changes',
  sub_clients_form_edit_sub_client_button: 'Save Changes',
  sub_clients_form_cancel_button: 'Discard',
  sub_clients_form_submitting_sub_client_button: 'Submitting...',
  sub_clients_name_required_form_error: 'Name is required',
  sub_clients_email_required_form_error: 'Email is required',
  sub_clients_focal_user_id_required_form_error:
           'Focal User is required',
  sub_clients_email_valid_form_error: 'Must be a valid email',
  sub_clients_city_required_form_error: 'City is required',
  sub_clients_client_required_form_error: 'Client is required',
  sub_clients_country_required_form_error: 'Country is required',
  sub_clients_add_form_success: 'Sub Client created successfully!',
  sub_clients_edit_form_success: 'Sub Client updated successfully!',
  sub_clients_form_api_error: 'Internal Server Error',
  sub_clients_form_email_error: 'Email already exists',
  sub_clients_no_record_found: 'No Sub Clients Found',
  sub_client_deactivated_message: 'Sub Client deactivated successfully',
  sub_client_activated_message: 'Sub Client Activated successfully',
  sub_clients_bulk_no_user_is_selected_error_message:
           'Please select records to De-activate',
  sub_clients_bulk_deactivated_message:
           '{{ USERS_COUNT }} Sub Client(s) are deactivated successfully',
  sub_client_single_stats_table_title: 'Status',
  /* END Sub Clients */
  sub_client_single_first_bread_crumb: 'Sub Clients',
  sub_client_single_second_bread_crumb: 'View Sub Client',
  sub_client_single_country_label: 'Country',
  sub_client_single_devices_label: 'Devices',
  sub_client_single_edit_button: 'Edit',
  sub_client_single_users_tab: 'Users',
  sub_client_single_apis_tab: 'API Keys',
  sub_client_single_filter_search: 'Search',
  sub_client_single_add_new_user_button: 'Add User',
  sub_client_single_add_new_api_key_button: 'Add API Key',
  sub_client_single_role_id_filter: 'Role',
  sub_client_single_users_search: 'Search',
  sub_client_single_filter_reset_button: 'Reset',
  sub_client_single_users_all: 'ALL',
  sub_client_single_users_active: 'ACTIVE',
  sub_client_single_users_inactive: 'INACTIVE',
  sub_client_single_filter_delete_button: 'Delete',
  sub_client_single_users_table_name: 'Name',
  sub_client_single_users_table_address: 'Address',
  sub_client_single_users_table_role_id: 'Role',
  sub_client_single_users_table_status: 'Status',
  sub_client_single_users_table_added: 'Added',
  sub_client_single_users_table_no_record_found: 'No Record(s) Found',
  sub_client_single_table_row_per_page: 'Rows Per Page',
  sub_client_single_stats_active_devices_label: 'Online Devices',
  sub_client_single_stats_error_devices_label: 'Error Devices',
  sub_client_single_no_user_selected_for_de_activation_message:
           'Please select records to De-activate',
  /* END Sub Clients Single */
  /* Users */
  users_bread_crumb_main_title: 'Users',
  add_users_bread_crumb_first_link: 'Add User',
  add_users_form_header: 'Add User',
  edit_users_bread_crumb_first_link: 'Edit User',
  edit_users_form_header: 'Edit User',
  users_form_first_name_field: 'First Name',
  users_form_last_name_field: 'Last Name',
  users_form_email_field: 'Email Address',
  users_form_official_email_field: 'Offical Email Address',
  users_form_phone_field: 'Phone Number',
  users_form_official_phone_number_field: 'Offical Phone Number',
  users_form_password_field: 'Password',
  users_form_role_id_field: 'Role',
  users_form_select_role_id_field: 'Select Role',
  users_form_street_number_field: 'Street Number',
  users_form_house_number_field: 'House Number',
  users_form_neighbourhood_field: 'Neighbourhood',
  users_form_postal_code_field: 'Postal Code',
  users_form_city_field: 'City',
  users_form_country_field: 'Country',
  users_form_email_verification_field: 'Email Verification',
  users_form_email_verification_sub_title_field:
           'Disabling this will automatically send the user a verification email',
  users_form_add_user_button: 'Save Changes',
  users_form_edit_user_button: 'Save Changes',
  users_form_cancel_button: 'Discard',
  users_form_submitting_user_button: 'Submitting...',
  users_first_name_required_form_error: 'First Name is required',
  users_last_name_required_form_error: 'Last Name is required',
  users_email_required_form_error: 'Email is required',
  users_email_valid_form_error: 'Must be a valid email',
  users_password_required_form_error: 'Password is required',
  users_country_required_form_error: 'Country is required',
  users_role_id_required_form_error: 'Role is required',
  users_password_form_error:
           'Password must contain atleast one alphabet of uppercase, lowercase, number and special character',
  users_add_form_success: 'User created successfully!',
  users_edit_form_success: 'User updated successfully!',
  users_form_api_error: 'Internal Server Error',
  users_form_email_error: 'Email already exists',
  users_no_record_found: 'No Users Found',
  user_deactivated_message: 'User deactivated successfully',
  user_activated_message: 'User Activated successfully',
  user_email_verified_title: 'Email Verified',
  user_email_verified_sub_title:
           'Disabling this will automatically send the user a verification email',
  users_bulk_no_user_is_selected_error_message:
           'Please select records to De-activate',
  users_bulk_deactivated_message:
           '{{ USERS_COUNT }} User(s) are deactivated successfully',
  /** Projects */
  projects: 'Projects',
  projects_all: 'ALL',
  projects_active: 'ACTIVE',
  projects_inactive: 'INACTIVE',
  projects_unassigned: 'UNASSIGNED',
  projects_add_new: 'Add New',
  projects_filter_search: 'Search',
  projects_sort_by_filter_label: 'Sort by',
  projects_sort_by_value_1: 'Date(newest)',
  projects_sort_by_value_2: 'Date(oldest)',
  projects_sort_by_value_3: 'Alphabetical',
  projects_country_filter_label: 'Country',
  projects_filter_reset_button: 'Reset',
  projects_filter_delete_button: 'Delete',
  projects_table_name: 'Name',
  projects_table_batch_number: 'Batch Number',
  projects_table_client: 'Clients',
  projects_table_devices: 'Devices',
  projects_table_added: 'Added',
  projects_table_status: 'Status',
  projects_table_row_per_page: 'Rows Per Page',
  projects_bread_crumb_main_title: 'Projects',
  add_projects_bread_crumb_first_link: 'Add Project',
  add_projects_form_header: 'Add Project',
  edit_projects_bread_crumb_first_link: 'Edit Project',
  edit_projects_form_header: 'Edit Project',
  projects_form_project_name_field: 'Name',
  projects_form_email_field: 'Email Address',
  projects_form_client_field: 'Client',
  projects_form_sub_client_field: 'Sub Client',
  projects_form_user_field: 'User',
  projects_form_description_field: 'Description',
  projects_form_add_project_button: 'Save Changes',
  projects_form_edit_project_button: 'Save Changes',
  projects_form_cancel_button: 'Discard',
  projects_form_submitting_sub_client_button: 'Submitting...',
  projects_name_required_form_error: 'Name is required',
  projects_form_unqiue_project_name_error: 'Project Name must be unique',
  projects_batch_number_required_form_error: 'Batch Number is required',
  projects_client_required_form_error: 'Client is required',
  projects_description_required_form_error: 'Description is required',
  projects_add_form_success: 'Project created successfully!',
  projects_edit_form_success: 'Project updated successfully!',
  projects_form_api_error: 'Internal Server Error',
  projects_no_record_found: 'No Projects Found',
  projects_deactivated_message: 'Project deactivated successfully',
  projects_activated_message: 'Project Activated successfully',
  projects_bulk_no_user_is_selected_error_message:
           'Please select records to De-activate',
  projects_bulk_deactivated_message:
           '{{ USERS_COUNT }} Project(s) are deactivated successfully',
  projects_single_stats_table_title: 'Status',
  /** Devices */
  devices: 'Devices',
  devices_all: 'All',
  devices_active: 'Online',
  devices_inactive: 'Offline',
  devices_error: 'ERROR',
  devices_register_enabled: 'Enabled',
  devices_register_disabled: 'Disabled',
  devices_add_new: 'Add New',
  devices_name: 'Name',
  devices_address: 'Address',
  devices_client: 'Client',
  devices_project: 'Project',
  devices_status: 'Status',
  device_is_online: 'Device is Online',
  device_is_offline: 'Device is Offline',
  devices_last_viewed: 'Last Viewed',
  devices_action: 'Action',
  devices_search: 'Search',
  devices_row_per_page: 'Rows Per Page',
  device_rssi_title: 'RSSI',
  device_status_reg_105_no_error_255: 'No Error',
  device_status_menu_list: JSON.stringify([
    {
      key: 'operating_state',
      value: 'Operating State',
    },
    {
      key: 'settings_hot_water',
      value: 'Settings Hot Water',
    },
    {
      key: 'settings_heating_and_cooling',
      value: 'Settings Heating and Cooling',
    },
    {
      key: 'manual_control',
      value: 'Manual Control',
    },
    {
      key: 'm_bus_meters',
      value: 'M-Bus Meters',
    },
    {
      key: 'live_hot_water',
      value: 'Live Hot Water',
    },
    {
      key: 'live_heating',
      value: 'Live Heating',
    },
    {
      key: 'live_cooling',
      value: 'Live Cooling',
    },
  ]),
  device_status_menu_keys_list: JSON.stringify([
    'operating_state',
    'settings_hot_water',
    'settings_heating_and_cooling',
    'manual_control',
    'm_bus_meters',
    'live_hot_water',
    'live_heating',
    'live_cooling',
  ]),
  devices_filter_search: 'Search',
  devices_sort_by_filter_label: 'Sort by',
  devices_sort_by_value_1: 'Date(newest)',
  devices_sort_by_value_2: 'Date(oldest)',
  devices_sort_by_value_3: 'Alphabetical',
  devices_country_filter_label: 'Country',
  devices_filter_reset_button: 'Reset',
  devices_filter_delete_button: 'Delete',
  devices_table_name: 'Name',
  devices_table_firmware: 'Firmware',
  devices_table_device_type: 'Device Type',
  devices_table_batch_number: 'Batch Number',
  devices_table_client: 'Clients',
  devices_table_devices: 'Devices',
  devices_table_added: 'Added',
  devices_table_status: 'Status',
  devices_table_row_per_page: 'Rows Per Page',
  devices_bread_crumb_main_title: 'Devices',
  add_devices_bread_crumb_first_link: 'Add Device',
  add_devices_form_header: 'Add Device',
  edit_devices_bread_crumb_first_link: 'Edit Device',
  edit_devices_form_header: 'Edit Device',
  devices_form_device_information_tab: 'Device Information',
  devices_form_name_field: 'Device Name',
  devices_form_type_field: 'Device Type',
  devices_form_ip_address_field: 'IP Address',
  devices_form_preset_field: 'Preset',
  devices_form_project_field: 'Project',
  devices_form_client_field: 'Client',
  devices_form_batch_number_field: 'Batch Number',
  devices_form_serial_number_field: 'Serial Number',
  devices_form_firmware_field: 'Firmware',
  devices_form_description_field: 'Description',
  devices_form_device_address_tab: 'Device Address',
  devices_form_street_number_field: 'Street Number',
  devices_form_house_number_field: 'House Number',
  devices_form_postal_code_field: 'Postal Code',
  devices_form_neighbourhood_field: 'Additional Info',
  devices_form_city_field: 'City',
  devices_form_country_field: 'Country',
  devices_form_additional_information_tab: 'Additional Information',
  devices_form_sn_heat_unit_field: 'SN Heat Unit',
  devices_form_article_number_field: 'Article Number',
  devices_form_prod_order_nr_week_field: 'Product Order Number',
  devices_form_serial_number_mercurius_field: 'Serial Number Mercurius',
  devices_form_sim_card_number_field: 'Sim Card Number',
  devices_form_customer_ref_field: 'Customer Ref',
  devices_form_production_date_field: 'Production Date',
  devices_form_order_number_field: 'Order Number',
  devices_form_product_number_field: 'Product Number',
  devices_form_model_number_field: 'Model Number',
  devices_form_note_field: 'Note',
  devices_form_settings_tab: 'Settings',
  devices_form_device_interval_field: 'Interval',
  devices_form_is_device_enabled_sensor_field: 'Enable sensor data',
  devices_form_is_device_activated_field: 'Deactivate device',
  devices_form_mbus_tab: 'Mbus',
  devices_form_is_device_enabled_heat_meter_field: 'Enable heat meter',
  devices_form_is_device_enabled_cool_meter_field: 'Enable cool meter',
  devices_form_is_device_enabled_electric_meter_field:
           'Enable electricity meter',
  devices_form_is_device_enabled_bi_directional_meter_field:
           'Enable bi meter',
  devices_form_is_device_enabled_water_meter_field: 'Enable water meter',
  devices_form_is_device_enabled_mbus_search_meter_button:
           'M-Bus search',
  devices_form_add_device_button: 'Save Changes',
  devices_form_edit_device_button: 'Save Changes',
  devices_form_cancel_button: 'Discard',
  devices_form_submitting_sub_client_button: 'Submitting...',
  devices_name_required_form_error: 'Name is required',
  devices_batch_number_required_form_error: 'Batch Number is required',
  devices_serial_number_required_form_error: 'Serial Number is required',
  devices_sn_heat_number_numeric_form_error:
           'SN Heat Unit must be a number',
  devices_article_number_form_error: 'Article Number is required',
  devices_prod_order_nr_week_form_error:
           'Product Order Number is required',
  devices_serial_number_mercurius_form_error:
           'Serial Number Mercurius is required',
  devices_sim_card_number_form_error: 'Sim Card Number is required',
  devices_device_interval_numeric_form_error:
           'Device Interval must be a number',
  devices_client_required_form_error: 'Client is required',
  devices_project_required_form_error: 'Project is required',
  devices_preset_required_form_error: 'Preset is required',
  devices_description_required_form_error: 'Description is required',
  devices_add_form_success: 'Device created successfully!',
  devices_edit_form_success: 'Device updated successfully!',
  devices_form_unique_error:
           'Serial Number and Serial Number Mercurius must be unique',
  devices_form_api_error: 'Internal Server Error',
  devices_no_record_found: 'No Devices Found',
  devices_deactivated_message: 'Device deactivated successfully',
  devices_activated_message: 'Device Activated successfully',
  devices_search_mbus_registers_success:
           'The message has been successfully sent to device.',
  devices_search_mbus_registers_missing_serial_number_error:
           'Please fill in serial number to proceed.',
  devices_bulk_no_user_is_selected_error_message:
           'Please select records to De-activate',
  devices_bulk_deactivated_message:
           '{{ USERS_COUNT }} Device(s) are deactivated successfully',
  // Devices Single
  device_single_page_title: 'Device Details',
  devices_single_bread_crumb_main_title: 'Devices',
  devices_single_bread_crumb_first_link: 'View Device',
  devices_single_basic_details_client: 'CLIENT',
  devices_single_basic_details_serial_number: 'SERIAL NO',
  devices_single_basic_details_firmware: 'FIRMWARE',
  devices_single_basic_details_type: 'TYPE',
  devices_single_edit_device_button: 'Edit',
  device_single_rss_device_status: 'Device Status',
  device_single_rss_signal_strength: 'Signal Strength',
  device_single_temparature_tab: 'Temperature',
  device_single_temparature_title: 'Routing Temperature change/week',
  device_single_temparature_hr: 'Hr',
  device_single_status_tab: 'Status',
  device_single_event_tab: 'Events',
  device_single_device_details_tab: 'Info',
  device_single_device_api_timer_seconds_label: 'Seconds',
  device_single_device_api_timer_minute_label: 'Minute',
  device_single_device_api_timer_minutes_label: 'Minutes',
  device_single_recall_device_status_api_timer_field: 'API Interval',
  devices_single_last_seen: 'Last Seen',
  devices_single_fetch_registers: 'Fetch Registers',
  fetch_registers_success:
           'The message has been successfully sent to device.',
  write_registers_success:
           'Write message has been successfully sent to device.',
  write_registers_error: 'Please update the value to proceed.',
  fetch_registers_m_bus_meters_not_supported:
           'Mbus Meters are not supported at the moment.',
  // Operating Status
  business_situation_operating_status_title: 'Operating State',
  device_status_nr: 'NR',
  device_status_name: 'Name',
  device_status_value: 'Value',
  business_situation_operating_status_100: 'CH Control State',
  business_situation_operating_status_101: 'DHW Control State',
  business_situation_operating_status_102: 'Preheat',
  business_situation_operating_status_103:
           'Anti-Legionella Control State',
  business_situation_operating_status_104: 'Cooling Control State',
  business_situation_operating_status_1802: 'TE Heating Element State',
  business_situation_operating_status_105: 'Error Code',
  business_situation_operating_status_106: 'Software version',
  business_situation_operating_status_107: 'StepperMotor1',
  business_situation_operating_status_108: 'StepperMotor2',
  business_situation_operating_status_109: 'Pump',
  business_situation_operating_status_110: 'Valve1(6-way-valve)',
  business_situation_operating_status_113: 'Room Thermostat Input',
  business_situation_operating_status_114: 'OpenTherm Indicator',
  business_situation_operating_status_115: 'Appliance Type',
  // Business situation
  business_situation_measured_values_title: 'Measurements',
  business_situation_measured_values_200: 'Hot Water Flow Rate',
  business_situation_measured_values_201: 'Hot Water Temperature',
  business_situation_measured_values_202: 'Primary Supply',
  business_situation_measured_values_203: 'Primary Return',
  business_situation_measured_values_205: 'Secondary Supply',
  business_situation_measured_values_206: 'Secondary Return',
  business_situation_measured_values_204: 'CH Calculated Setpoint',
  business_situation_measured_values_207: 'Room Temperature (OT)',
  business_situation_measured_values_208: 'CH Setpoint (OT)',
  business_situation_measured_values_210: 'Water Pressure',
  // Operating time
  business_situation_operating_time_title: 'Operating Time',
  business_situation_operating_time_800: 'System Run Time',
  business_situation_operating_time_801: 'CH Burn Time',
  business_situation_operating_time_802: 'Cooling Time',
  business_situation_operating_time_803: 'DHW Burn Time',
  business_situation_operating_time_804: 'Number of Tappings',
  business_situation_operating_time_805: 'DHW Volume',
  business_situation_operating_time_806: 'Stroomonderbrekingen',
  // Warmtapwater
  settings_dhw_warm_tap_water_title: 'Settings Hot Water',
  settings_dhw_warm_tap_water_300: 'DHW Setpoint',
  settings_dhw_warm_tap_water_301: 'Preheat mode',
  settings_dhw_warm_tap_water_302: 'Comfort Setpoint',
  settings_dhw_warm_tap_water_303: 'Flow rate start threshold',
  settings_dhw_warm_tap_water_304: 'DHW Enable',
  // Thermal disinfection
  settings_thermal_disinfection_title: 'Settings Thermal Disinfection',
  settings_thermal_disinfection_400: 'Aleg Enabled',
  settings_thermal_disinfection_401: 'Aleg Setpoint',
  settings_thermal_disinfection_402: 'Aleg Burn Time',
  settings_thermal_disinfection_403: 'Aleg Wait Time',
  // TE-Booster
  settings_dhw_te_booster_title: 'Settings TE-Booster',
  settings_dhw_te_booster_1800: 'TE-Booster Enabled',
  settings_dhw_te_booster_1804: 'TE-Booster Setpoint',
  settings_dhw_te_booster_1805: 'TE-Booster Setpoint Hysteresis',
  settings_dhw_te_booster_1812: 'TE-Booster Setpoint Timeout',
  settings_dhw_te_booster_1813: 'TE-Booster Setpoint Timer',
  settings_dhw_te_booster_1815: 'TE-Booster Threshold',
  settings_dhw_te_booster_1816: 'TE-Booster Taped Volume',
  // Heating
  heating_and_cooling_settings_heating_title: 'Settings Heating',
  heating_and_cooling_settings_heating_500: 'CH Max Setpoint',
  heating_and_cooling_settings_heating_501: 'CH Min Setpoint',
  heating_and_cooling_settings_heating_506: 'Max Primary Return',
  heating_and_cooling_settings_heating_507:
           'Max Primary Return Percentage',
  heating_and_cooling_settings_heating_508: 'Delta Prim CH Setpoint',
  heating_and_cooling_settings_heating_510: 'Max Heating Temperature',
  heating_and_cooling_settings_heating_509: 'Delta Prim Min',
  heating_and_cooling_settings_heating_514: 'CH Setpoint',
  heating_and_cooling_settings_heating_515: 'CH Enable',
  // Cooling
  heating_and_cooling_settings_cooling_title: 'Settings Cooling',
  heating_and_cooling_settings_cooling_600: 'CoolTempSetpoint',
  heating_and_cooling_settings_cooling_601: 'Cool MaxPrimary Return',
  heating_and_cooling_settings_cooling_602:
           'Cool % of Max Setpoint compensation',
  heating_and_cooling_settings_cooling_605: 'Max Cooling Temp',
  heating_and_cooling_settings_cooling_606: 'Cool setpoint min',
  heating_and_cooling_settings_cooling_607: 'Cool setpoint max',
  heating_and_cooling_settings_cooling_611: 'Cooling Enable',
  // Secondary General
  heating_and_cooling_settings_secondary_general_title:
           'Settings General',
  heating_and_cooling_settings_secondary_general_512: 'CH Min Pressure',
  heating_and_cooling_settings_secondary_general_513: 'CH Max Pressure',
  heating_and_cooling_settings_secondary_general_603:
           'CoolHeatWaitingTime',
  heating_and_cooling_settings_secondary_general_604:
           '6way valve waiting time',
  // Changeover WKW
  heating_and_cooling_settings_changeover_wkw_title: 'Settings 3.5 Pipe',
  heating_and_cooling_settings_changeover_wkw_1850:
           '3.5Pipe Configuration',
  heating_and_cooling_settings_changeover_wkw_1851:
           'Cooling detection threshold',
  heating_and_cooling_settings_changeover_wkw_1852:
           'Heating detection threshold',
  heating_and_cooling_settings_changeover_wkw_1853:
           'Heating lowered setpoint',
  heating_and_cooling_settings_changeover_wkw_1854:
           'Lowered setpoint used',
  heating_and_cooling_settings_changeover_wkw_1855:
           'Predetection MCV2 position',
  heating_and_cooling_settings_changeover_wkw_1856: 'Detection interval',
  heating_and_cooling_settings_changeover_wkw_1857:
           'Detection interval timer',
  heating_and_cooling_settings_changeover_wkw_1858:
           'Predetection timeout',
  heating_and_cooling_settings_changeover_wkw_1859: 'Predetection timer',
  heating_and_cooling_settings_changeover_wkw_1860:
           'Configuration switch timer',
  heating_and_cooling_settings_changeover_wkw_1861: 'Switch timeout',
  heating_and_cooling_settings_changeover_wkw_1862:
           'Configuration switch threshold',
  heating_and_cooling_settings_changeover_wkw_1863:
           'Boost function mode',
  heating_and_cooling_settings_changeover_wkw_1864:
           'Boost detection timer',
  heating_and_cooling_settings_changeover_wkw_1865:
           'Boost detection timeout',
  heating_and_cooling_settings_changeover_wkw_1866:
           'Maximum boost position',
  // Manual Operation
  settings_manual_operation_title: 'Manual Control',
  settings_manual_operation_700: 'Manual Control enabled',
  settings_manual_operation_701: 'MCV-1 Manual control',
  settings_manual_operation_702: 'MCV-2 Manual control',
  settings_manual_operation_703: 'Pump (P1) Manual control',
  settings_manual_operation_704: 'Valve1',
  settings_manual_operation_706: 'Heat demand status output',
  settings_manual_operation_707: 'MCV-1 Manual control[%]',
  settings_manual_operation_708: 'MCV-1 Manual control[%]',
  settings_manual_operation_1801: 'Elektisch element TE-Booster',
  settings_manual_operation_709: 'Manual control timeout',
  // Live Tap Water
  live_tap_water_title: 'Live Hot Water',
  live_tap_water_101: 'DHW Control State',
  live_tap_water_107: 'StepperMotor1',
  live_tap_water_200: 'Flow rate',
  live_tap_water_201: 'DHW temperature',
  live_tap_water_202: 'Primary Supply',
  live_tap_water_203: 'Primary Return',
  live_tap_water_1007: 'Primary flow M-Bus meter',
  live_tap_water_1008: 'Power',
  // Live CV
  live_cv_title: 'Live Heating',
  live_cv_100: 'CH Control state',
  live_cv_108: 'StepperMotor2',
  live_cv_202: 'Primary Supply',
  live_cv_203: 'Primary Return',
  live_cv_204: 'CH calculated setpoint',
  live_cv_205: 'Secondary Supply',
  live_cv_206: 'Secondary return',
  live_cv_113: 'Room thermostat input',
  live_cv_210: 'Water pressure',
  live_cv_1007: 'Primary flow M-Bus meter',
  live_cv_1008: 'Power',
  // Live Cooling
  live_cooling_title: 'Live Cooling',
  live_cooling_104: 'Cooling control state',
  live_cooling_109: 'Pump',
  live_cooling_110: 'Valve1 (6-way valve)',
  live_cooling_113: 'Room thermostat input',
  live_cooling_108: 'StepperMotor2',
  live_cooling_202: 'Primary Supply',
  live_cooling_204: 'Calculated setpoint',
  live_cooling_205: 'Secondary Supply',
  live_cooling_206: 'Secondary return',
  // M Bus Limit
  m_bus_limit_title: 'M Bus Limit',
  m_bus_limit_1500: 'Begrenzing inschakelen',
  m_bus_limit_1501: 'Serienummer begrenzing meter',
  m_bus_limit_1503: 'Begrens status',
  m_bus_limit_1504: 'Begrenzing configuratie DHW (h) en CH (l)',
  m_bus_limit_1505: 'Begrens vermogen',
  m_bus_limit_1506: 'Begrens flow',
  m_bus_limit_1507: 'Begrens retour temperatuur (Alleen voor CH)',
  // Meter 1
  m_bus_meter_1_title: 'Meter 1 (Heating)',
  m_bus_meter_1_0000: 'Serial Number',
  m_bus_meter_1_0002: 'Manufacturer',
  m_bus_meter_1_0003: 'Meter Type',
  m_bus_meter_1_0004: 'Status',
  m_bus_meter_1_0005: 'Flow',
  m_bus_meter_1_0006: 'Power',
  m_bus_meter_1_0007: 'Supply Temperature',
  m_bus_meter_1_0008: 'Return Temperature',
  m_bus_meter_1_0010: 'Heating Energy',
  m_bus_meter_1_0012: 'Volume',
  // Meter 2
  m_bus_meter_2_title: 'Meter 2 (Cooling)',
  m_bus_meter_2_0100: 'Serial Number',
  m_bus_meter_2_0102: 'Manufacturer',
  m_bus_meter_2_0103: 'Meter Type',
  m_bus_meter_2_0104: 'Status',
  m_bus_meter_2_0105: 'Flow',
  m_bus_meter_2_0106: 'Power',
  m_bus_meter_2_0107: 'Supply Temperature',
  m_bus_meter_2_0108: 'Return Temperature',
  m_bus_meter_2_0110: 'Cooling Energy',
  m_bus_meter_2_0112: 'Volume',
  // Meter 3
  m_bus_meter_3_title: 'Meter 3 (Electricity)',
  m_bus_meter_3_0200: 'Serial Number',
  m_bus_meter_3_0202: 'Manufacturer',
  m_bus_meter_3_0203: 'Meter Type',
  m_bus_meter_3_0204: 'Status',
  m_bus_meter_3_0206: 'Power',
  m_bus_meter_3_0210: 'Energy',
  m_bus_meter_3_0214: 'Current',
  // Meter 4
  m_bus_meter_4_title: 'Meter 4 (Water)',
  m_bus_meter_4_0300: 'Serial Number',
  m_bus_meter_4_0302: 'Manufacturer',
  m_bus_meter_4_0303: 'Meter Type',
  m_bus_meter_4_0304: 'Status',
  m_bus_meter_4_0305: 'Flow',
  m_bus_meter_4_0307: 'Supply Temperature',
  m_bus_meter_4_0312: 'Volume',
  // Meter 5
  m_bus_meter_5_title: 'Meter 5 (Bi Directional)',
  m_bus_meter_5_0400: 'Serial Number',
  m_bus_meter_5_0402: 'Manufacturer',
  m_bus_meter_5_0403: 'Meter Type',
  m_bus_meter_5_0404: 'Status',
  m_bus_meter_5_0405: 'Flow',
  m_bus_meter_5_0406: 'Power',
  m_bus_meter_5_0407: 'Supply Temperature',
  m_bus_meter_5_0408: 'Return Temperature',
  m_bus_meter_5_0410: 'Heating Energy',
  m_bus_meter_5_0412: 'Volume',
  m_bus_meter_5_0414: 'Cooling Energy',
  // History Block Errors
  history_block_errors_title: 'History Block Errors',
  history_block_errors_860: 'Block Error 1',
  history_block_errors_861: 'Block Error 2',
  history_block_errors_862: 'Block Error 3',
  history_block_errors_863: 'Block Error 4',
  history_block_errors_864: 'Block Error 5',
  history_block_errors_865: 'Block Error 6',
  history_block_errors_866: 'Block Error 7',
  history_block_errors_867: 'Block Error 8',
  history_block_errors_868: 'Block Error 9',
  history_block_errors_869: 'Block Error 10',
  history_block_errors_870: 'Block Error 11',
  history_block_errors_871: 'Block Error 12',
  history_block_errors_872: 'Block Error 13',
  history_block_errors_873: 'Block Error 14',
  history_block_errors_874: 'Block Error 15',
  // Events
  device_events_type_filter_label: 'Event Type',
  device_events_type_filter_value_1: 'Audit',
  device_events_type_filter_value_2: 'Error',
  // Presets
  presets: 'Presets',
  presets_all: 'All',
  presets_active: 'ACTIVE',
  presets_inactive: 'INACTIVE',
  presets_add_new: 'Add New',
  presets_filter_search: 'Search',
  presets_filter_reset_button: 'Reset',
  presets_filter_delete_button: 'Delete',
  presets_table_name: 'Name',
  presets_table_status: 'Status',
  presets_updated_date: 'Updated At',
  presets_action: 'Actions',
  presets_table_row_per_page: 'Rows Per Page',
  presets_status_menu_list: JSON.stringify([
    'Settings Hot Water',
    'Settings Heating and Cooling',
    'Manual Control',
  ]),
  add_presets_title: 'Add Preset',
  edit_presets_title: 'Edit Preset',
  presets_form_name_required: 'Preset Name is required',
  presets_form_register_required: 'Register value is required',
  presets_form_add_preset_button: 'Save Changes',
  presets_form_edit_preset_button: 'Save Changes',
  presets_form_cancel_button: 'Discard',
  presets_add_form_success: 'Preset created successfully!',
  presets_edit_form_success: 'Preset updated successfully!',
  presets_form_api_error: 'Internal Server Error',
  presets_no_record_found: 'No Presets Found',
  presets_deactivated_message: 'Preset deactivated successfully',
  presets_activated_message: 'Preset Activated successfully',
  presets_bulk_no_user_is_selected_error_message:
           'Please select records to De-activate',
  presets_bulk_deactivated_message:
           '{{ USERS_COUNT }} Preset(s) are deactivated successfully',
  // Audits
  audits: 'Audits',
  audits_all: 'All',
  audits_active: 'Audit',
  audits_inactive: 'Error',
  audits_filter_search: 'Search',
  audits_client_filter_label: 'Client',
  audits_error_code_filter_label: 'Error Code',
  audits_project_filter_label: 'Project',
  audits_order_number_filter_label: 'Order Number',
  audits_events_type_filter_label: 'Event Type',
  audits_date_range_filter_label: 'Date Range',
  audits_events_type_filter_value_1: 'Audit',
  audits_events_type_filter_value_2: 'Error',
  audits_reset_filters_button: 'Reset',
  audits_date: 'Date',
  audits_device: 'Device',
  audits_client: 'Client',
  audits_message: 'Message',
  audits_table_row_per_page: 'Rows Per Page',
  audits_table_no_record_found: 'No Record(s) Found',
  // Settings
  settings_update_profile_title: 'Update Profile',
  settings_notification_title: 'Notifications',
  settings_notification_window_exceptions_title:
           'Notify when operating window exceptions occur',
  settings_notification_device_error_title:
           'Notify when a device is in error mode',
  settings_notification_channels_title: 'Notifications Channels',
  settings_notification_use_email_title: 'Use Email',
  /* Admins */
  admins: 'Admins',
  admins_first_bread_crumb: 'Admins',
  admins_filter_search: 'Search',
  admins_add_new: 'Add User',
  admins_filter_reset_button: 'Reset',
  admins_filter_delete_button: 'Delete',
  admins_search: 'Search',
  admins_all: 'ALL',
  admins_active: 'ACTIVE',
  admins_inactive: 'INACTIVE',
  admins_table_name: 'Name',
  admins_table_address: 'Address',
  admins_table_role_id: 'Role',
  admins_table_status: 'Status',
  admins_table_added: 'Added',
  admins_table_no_record_found: 'No Record(s) Found',
  admins_table_row_per_page: 'Rows Per Page',
  admins_no_user_selected_for_de_activation_message:
           'Please select records to De-activate',
  admins_bread_crumb_main_title: 'Admins',
  add_admins_bread_crumb_first_link: 'Add Admin',
  add_admins_form_header: 'Add Admin',
  edit_admins_bread_crumb_first_link: 'Edit Admin',
  edit_admins_form_header: 'Edit Admin',
  admins_form_first_name_field: 'First Name',
  admins_form_last_name_field: 'Last Name',
  admins_form_email_field: 'Email Address',
  admins_form_official_email_field: 'Offical Email Address',
  admins_form_phone_field: 'Phone Number',
  admins_form_official_phone_number_field: 'Offical Phone Number',
  admins_form_password_field: 'Password',
  admins_form_role_id_field: 'Role',
  admins_form_select_role_id_field: 'Select Role',
  admins_form_street_number_field: 'Street Number',
  admins_form_house_number_field: 'House Number',
  admins_form_neighbourhood_field: 'Neighbourhood',
  admins_form_postal_code_field: 'Postal Code',
  admins_form_city_field: 'City',
  admins_form_country_field: 'Country',
  admins_form_email_verification_field: 'Email Verification',
  admins_form_email_verification_sub_title_field:
           'Disabling this will automatically send the admin a verification email',
  admins_form_add_admin_button: 'Save Changes',
  admins_form_edit_admin_button: 'Save Changes',
  admins_form_cancel_button: 'Discard',
  admins_form_submitting_admin_button: 'Submitting...',
  admins_first_name_required_form_error: 'First Name is required',
  admins_last_name_required_form_error: 'Last Name is required',
  admins_email_required_form_error: 'Email is required',
  admins_email_valid_form_error: 'Must be a valid email',
  admins_password_required_form_error: 'Password is required',
  admins_country_required_form_error: 'Country is required',
  admins_role_id_required_form_error: 'Role is required',
  admins_password_form_error:
           'Password must contain atleast one alphabet of uppercase, lowercase, number and special character',
  admins_add_form_success: 'Admin created successfully!',
  admins_edit_form_success: 'Admin updated successfully!',
  admins_form_api_error: 'Internal Server Error',
  admins_form_email_error: 'Email already exists',
  admins_no_record_found: 'No Admins Found',
  admins_deactivated_message: 'Admin deactivated successfully',
  admins_activated_message: 'Admin Activated successfully',
  admins_email_verified_title: 'Email Verified',
  admins_email_verified_sub_title:
           'Disabling this will automatically send the admin a verification email',
  admins_bulk_no_admin_is_selected_error_message:
           'Please select records to De-activate',
  admins_bulk_deactivated_message:
           '{{ USERS_COUNT }} Admin(s) are deactivated successfully',
  // Search Results
  search_result_title: 'Search Results: ',
  search_users: 'Users',
  search_clients: 'Clients',
  search_sub_clients: 'Sub Clients',
  search_devices: 'Devices',
  search_presets: 'Presets',
  search_projects: 'Projects',
  // New Locales
  no_error_devices_found: 'No Error Devices Found',
  project_single_first_bread_crumb: 'Projects',
  project_single_second_bread_crumb: 'View Project',
  project_single_client_label: 'Client',
  project_single_sub_client_label: 'Sub Client',
  project_single_devices_label: 'Devices',
  project_single_edit_button: 'Edit',
  project_single_devices_tab: 'Devices',
  client_single_view_more_errors_button: 'View More',
  client_error_single_devices_tab: 'Error Devices',
  client_error_single_device_name: 'Device',
  client_error_single_device_error_code: 'Error Code',
  client_error_single_device_error_date_time: 'Date',
  devices_bulk_edit_registers_button: 'Bulk Edit Registers',
  devices_bulk_edit_ota_button: 'Bulk Edit OTA',
  client_error_single_device_errors_table_row_per_page: 'Rows Per Page',
  client_error_single_device_error_no_record_found: 'No Record(s) Found',
  devices_bulk_edit_registers_title: 'Device Registers Bulk Update',
  devices_bulk_edit_registers_form_submit_button: 'Bulk Update',
  devices_bulk_edit_registers_form_cancel_button: 'Cancel',
  devices_bulk_edit_registers_form_success:
           'Message is forwarded to selected device(s)',
  devices_bulk_edit_registers_form_error:
           'Please fill in atleast one register value',
  devices_bulk_edit_registers_form_api_error: 'Internal Server Error',
  devices_bulk_edit_ota_title: 'Device OTA Bulk Update',
  devices_bulk_edit_ota_form_firmware_field: 'Firmware',
  devices_bulk_edit_ota_form_submit_button: 'Bulk Update',
  devices_bulk_edit_ota_form_cancel_button: 'Cancel',
  devices_bulk_edit_ota_form_success:
           'Message is forwarded to selected device(s)',
  devices_bulk_edit_ota_form_firmware_required_error:
           'Firmware is required',
  devices_bulk_edit_ota_form_api_error: 'Internal Server Error',
  devices_bulk_edit_registers_selected_devices:
           'Devices Selected for Bulk Edit',
  // Device Status Table Pop Over
  custom_popover_min_value: 'Min Value',
  custom_popover_max_value: 'Max Value',
  custom_popover_list_options: 'Possible Values',
  custom_popover_short_description: 'Description',
  custom_popover_close_modal_button: 'Close',
  // Warmtapwater PopOver Short Description
  custom_popover_300_short_description: 'DHW Setpoint',
  custom_popover_301_short_description: 'Preheat mode',
  custom_popover_302_short_description: 'Comfort Setpoint',
  custom_popover_303_short_description: 'Flow rate start threshold',
  custom_popover_304_short_description: 'DHW Enable',
  // Thermal disinfection PopOver Short Description
  custom_popover_400_short_description: 'Aleg Enabled',
  custom_popover_401_short_description: 'Aleg Setpoint',
  custom_popover_402_short_description: 'Aleg Burn Time',
  custom_popover_403_short_description: 'Aleg Wait Time',
  // TE-Booster PopOver Short Description
  custom_popover_1800_short_description: 'TE-Booster Enabled',
  custom_popover_1804_short_description: 'TE-Booster Setpoint',
  custom_popover_1805_short_description:
           'TE-Booster Setpoint Hysteresis',
  custom_popover_1812_short_description: 'TE-Booster Setpoint Timeout',
  custom_popover_1813_short_description: 'TE-Booster Setpoint Timer',
  custom_popover_1815_short_description: 'TE-Booster Threshold',
  custom_popover_1816_short_description: 'TE-Booster Taped Volume',
  // Heating PopOver Short Description
  custom_popover_500_short_description: 'CH Max Setpoint',
  custom_popover_501_short_description: 'CH Min Setpoint',
  custom_popover_506_short_description: 'Max Primary Return',
  custom_popover_507_short_description: 'Max Primary Return Percentage',
  custom_popover_508_short_description: 'Delta Prim CH Setpoint',
  custom_popover_510_short_description: 'Max Heating Temperature',
  custom_popover_509_short_description: 'Delta Prim Min',
  custom_popover_514_short_description: 'CH Setpoint',
  custom_popover_515_short_description: 'CH Enable',
  // Cooling PopOver Short Description
  custom_popover_600_short_description: 'CoolTempSetpoint',
  custom_popover_601_short_description: 'Cool MaxPrimary Return',
  custom_popover_602_short_description:
           'Cool % of Max Setpoint compensation',
  custom_popover_605_short_description: 'Max Cooling Temp',
  custom_popover_606_short_description: 'Cool setpoint min',
  custom_popover_607_short_description: 'Cool setpoint max',
  custom_popover_611_short_description: 'Cooling Enable',
  // Secondary General PopOver Short Description
  custom_popover_512_short_description: 'CH Min Pressure',
  custom_popover_513_short_description: 'CH Max Pressure',
  custom_popover_603_short_description: 'CoolHeatWaitingTime',
  custom_popover_604_short_description: '6way valve waiting time',
  // Changeover WKW PopOver Short Description
  custom_popover_1850_short_description: '3.5Pipe Configuration',
  custom_popover_1851_short_description: 'Cooling detection threshold',
  custom_popover_1852_short_description: 'Heating detection threshold',
  custom_popover_1853_short_description: 'Heating lowered setpoint',
  custom_popover_1854_short_description: 'Lowered setpoint used',
  custom_popover_1855_short_description: 'Predetection MCV2 position',
  custom_popover_1856_short_description: 'Detection interval',
  custom_popover_1857_short_description: 'Detection interval timer',
  custom_popover_1858_short_description: 'Predetection timeout',
  custom_popover_1859_short_description: 'Predetection timer',
  custom_popover_1860_short_description: 'Configuration switch timer',
  custom_popover_1861_short_description: 'Switch timeout',
  custom_popover_1862_short_description:
           'Configuration switch threshold',
  custom_popover_1863_short_description: 'Boost function mode',
  custom_popover_1864_short_description: 'Boost detection timer',
  custom_popover_1865_short_description: 'Boost detection timeout',
  custom_popover_1866_short_description: 'Maximum boost position',
  // Manual Operation PopOver Short Description
  custom_popover_700_short_description: 'Manual Control enabled',
  custom_popover_701_short_description: 'MCV-1 Manual control',
  custom_popover_702_short_description: 'MCV-2 Manual control',
  custom_popover_703_short_description: 'Pump (P1) Manual control',
  custom_popover_704_short_description: 'Valve1',
  custom_popover_706_short_description: 'Heat demand status output',
  custom_popover_707_short_description: 'MCV-1 Manual control[%]',
  custom_popover_708_short_description: 'MCV-1 Manual control[%]',
  custom_popover_1801_short_description: 'Elektisch element TE-Booster',
  custom_popover_709_short_description: 'Manual control timeout',
  // Warmtapwater PopOver Long Description
  custom_popover_300_long_description: 'DHW Setpoint',
  custom_popover_301_long_description: 'Preheat mode',
  custom_popover_302_long_description: 'Comfort Setpoint',
  custom_popover_303_long_description: 'Flow rate start threshold',
  custom_popover_304_long_description: 'DHW Enable',
  // Thermal disinfection PopOver Long Description
  custom_popover_400_long_description: 'Aleg Enabled',
  custom_popover_401_long_description: 'Aleg Setpoint',
  custom_popover_402_long_description: 'Aleg Burn Time',
  custom_popover_403_long_description: 'Aleg Wait Time',
  // TE-Booster PopOver Long Description
  custom_popover_1800_long_description: 'TE-Booster Enabled',
  custom_popover_1804_long_description: 'TE-Booster Setpoint',
  custom_popover_1805_long_description: 'TE-Booster Setpoint Hysteresis',
  custom_popover_1812_long_description: 'TE-Booster Setpoint Timeout',
  custom_popover_1813_long_description: 'TE-Booster Setpoint Timer',
  custom_popover_1815_long_description: 'TE-Booster Threshold',
  custom_popover_1816_long_description: 'TE-Booster Taped Volume',
  // Heating PopOver Long Description
  custom_popover_500_long_description: 'CH Max Setpoint',
  custom_popover_501_long_description: 'CH Min Setpoint',
  custom_popover_506_long_description: 'Max Primary Return',
  custom_popover_507_long_description: 'Max Primary Return Percentage',
  custom_popover_508_long_description: 'Delta Prim CH Setpoint',
  custom_popover_510_long_description: 'Max Heating Temperature',
  custom_popover_509_long_description: 'Delta Prim Min',
  custom_popover_514_long_description: 'CH Setpoint',
  custom_popover_515_long_description: 'CH Enable',
  // Cooling PopOver Long Description
  custom_popover_600_long_description: 'CoolTempSetpoint',
  custom_popover_601_long_description: 'Cool MaxPrimary Return',
  custom_popover_602_long_description:
           'Cool % of Max Setpoint compensation',
  custom_popover_605_long_description: 'Max Cooling Temp',
  custom_popover_606_long_description: 'Cool setpoint min',
  custom_popover_607_long_description: 'Cool setpoint max',
  custom_popover_611_long_description: 'Cooling Enable',
  // Secondary General PopOver Long Description
  custom_popover_512_long_description: 'CH Min Pressure',
  custom_popover_513_long_description: 'CH Max Pressure',
  custom_popover_603_long_description: 'CoolHeatWaitingTime',
  custom_popover_604_long_description: '6way valve waiting time',
  // Changeover WKW PopOver Long Description
  custom_popover_1850_long_description: '3.5Pipe Configuration',
  custom_popover_1851_long_description: 'Cooling detection threshold',
  custom_popover_1852_long_description: 'Heating detection threshold',
  custom_popover_1853_long_description: 'Heating lowered setpoint',
  custom_popover_1854_long_description: 'Lowered setpoint used',
  custom_popover_1855_long_description: 'Predetection MCV2 position',
  custom_popover_1856_long_description: 'Detection interval',
  custom_popover_1857_long_description: 'Detection interval timer',
  custom_popover_1858_long_description: 'Predetection timeout',
  custom_popover_1859_long_description: 'Predetection timer',
  custom_popover_1860_long_description: 'Configuration switch timer',
  custom_popover_1861_long_description: 'Switch timeout',
  custom_popover_1862_long_description: 'Configuration switch threshold',
  custom_popover_1863_long_description: 'Boost function mode',
  custom_popover_1864_long_description: 'Boost detection timer',
  custom_popover_1865_long_description: 'Boost detection timeout',
  custom_popover_1866_long_description: 'Maximum boost position',
  // Manual Operation PopOver Long Description
  custom_popover_700_long_description: 'Manual Control enabled',
  custom_popover_701_long_description: 'MCV-1 Manual control',
  custom_popover_702_long_description: 'MCV-2 Manual control',
  custom_popover_703_long_description: 'Pump (P1) Manual control',
  custom_popover_704_long_description: 'Valve1',
  custom_popover_706_long_description: 'Heat demand status output',
  custom_popover_707_long_description: 'MCV-1 Manual control[%]',
  custom_popover_708_long_description: 'MCV-1 Manual control[%]',
  custom_popover_1801_long_description: 'Elektisch element TE-Booster',
  custom_popover_709_long_description: 'Manual control timeout',
};
