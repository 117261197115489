import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import type { SxProps } from '@material-ui/system';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import jwtDecode from 'jwt-decode';

const useStyles = makeStyles(() => ({
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: '70%',
    height: 110,
    objectFit: 'contain',
  },
}));

interface LogoProps {
  sx?: SxProps<Theme>;
}

const Logo: FC<LogoProps> = () => {
  const classes = useStyles();
  let logo = 'https://assets.it22.nl/it22/header/it22-layout-logo-black.webp';
  if (localStorage.getItem('accessToken')?.length > 0) {
    const decoded: any = jwtDecode(localStorage.getItem('accessToken'));
    const roles = decoded?.realm_access?.roles;
    const isClientUser = roles?.includes('client_admin') || roles?.includes('client_user');
    if (isClientUser) {
      if (localStorage.getItem('client_logo')?.length > 0) {
        const clientLogo = localStorage.getItem('client_logo');
        if (clientLogo?.length > 0) {
          logo = `https://uploadimages.fortes.it22.cloud/images/user/${clientLogo}`;
        }
      }
    }
  }
  return (
    <RouterLink
      to="/"
      className={classes.logoWrapper}
    >
      <img
        className={classes.logo}
        src={logo}
        alt="Logo"
      />
    </RouterLink>
  );
};

export default Logo;
