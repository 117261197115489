import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { messagesApi } from 'src/api/messagesApi';
import logger from 'src/utils/logger';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      borderRadius: '4px',
    },
  },
  attachment: {
    textDecoration: 'none',
    fontWeight: 200,
    color: '#000',
    '& img': {
      marginLeft: '10px',
    },

    '&:hover': {
      textDecoration: 'underline',
    }
  },
  button: {
    width: 200,
    height: 45,
    // height: 40,
    // color: '#C22027'
    border: '1px solid transparent'
  },
  cancelButton: {
    borderColor: '#c22027',
    borderRadius: 4,
    textTransform: 'uppercase'
  },
}));

const RecruitmentEdit: any = () => {
  const { recruitmentId } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [message, setMessage] = useState<any>();
  const getById = async () => {
    if (isSubmitting) {
      try {
        const res = await messagesApi.recruitmentGetById(recruitmentId);
        setMessage(res);
        setIsSubmitting(false);
      } catch (err) {
        logger(err, 'error');
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    getById();
  }, [isSubmitting, recruitmentId]);

  useEffect(() => {
    setIsSubmitting(true);
  }, [recruitmentId]);

  return (
    <>
      <Helmet>
        <title>View Recruitment Message</title>
      </Helmet>
      <Box
        className={classes.root}
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          p: 4,
        }}
      >
        <Typography
          color="textPrimary"
          variant="h5"
          mb={2}
        >
          View Request
        </Typography>
        <Box style={{ backgroundColor: '#fff', padding: '30px 20px', borderRadius: '10px', }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={6}
            >
              <InputLabel htmlFor="first_name_123">First Name</InputLabel>
              <TextField
                id="first_name_123"
                fullWidth
                disabled
                value={message?.first_name ? message?.first_name : 'N/A'}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <InputLabel htmlFor="last_name_123">Last Name</InputLabel>
              <TextField
                id="last_name_123"
                fullWidth
                disabled
                value={message?.last_name ? message?.last_name : 'N/A'}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <InputLabel htmlFor="email_123">Email</InputLabel>
              <TextField
                id="email_123"
                fullWidth
                disabled
                value={message?.email ?? 'N/A'}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <InputLabel htmlFor="linkedin_123">Linkedin</InputLabel>
              <TextField
                id="linkedin_123"
                fullWidth
                disabled
                value={message?.linkedin_url ? message?.linkedin_url : 'N/A'}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <InputLabel htmlFor="ad_date_123">Ad Date</InputLabel>
              <TextField
                id="ad_date_123"
                fullWidth
                disabled
                value={message?.ad_date ? message?.ad_date : 'N/A'}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <InputLabel htmlFor="created_at_123">Apply Date</InputLabel>
              <TextField
                id="created_at_123"
                fullWidth
                disabled
                value={message?.created_at ? moment(message?.created_at).format('MMM-D-YY') : 'N/A'}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <InputLabel htmlFor="phone_number_123">Phone Number</InputLabel>
              <TextField
                id="phone_number_123"
                fullWidth
                disabled
                value={message?.phone_number ? message?.phone_number : 'N/A'}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <InputLabel htmlFor="job_title_123">Job Title</InputLabel>
              <TextField
                id="job_title_123"
                fullWidth
                disabled
                value={message?.name ? message?.name : 'N/A'}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <InputLabel htmlFor="message_123">Message</InputLabel>
              <TextField
                id="message_123"
                fullWidth
                disabled
                multiline
                rows={3}
                value={message?.message ? message?.message : 'N/A'}
              />
            </Grid>
            {
            message?.blob_id !== '' && message?.blob_id !== null && message?.blob_id !== undefined && (
            <Grid
              item
              xs={12}
            >
              <a
                target="_blank"
                href={message?.blob_url}
                className={classes.attachment}
                rel="noreferrer"
              >
                Attachment Link
                {' '}
                <span>
                  <img
                    src="/static/icons/attachment-icon.svg"
                    alt="attachment icon"
                    width="20px"
                    height="20px"
                  />
                </span>
              </a>
            </Grid>
            )
          }
          </Grid>
        </Box>

        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', marginTop: '20px', }}>
          <Button
            type="button"
            onClick={() => navigate('/admin/recruitment-emails')}
            className={clsx(classes.button, classes.cancelButton)}
          >
            Close
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default RecruitmentEdit;
