import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
// import { ClientDeleteProps } from 'src/types/client';
import type { FC } from 'react';

import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
// import { clientApi } from 'src/api/clientApi';
// import { tagApi } from '../../../api/tagApi';
import { messagesApi } from 'src/api/messagesApi';

const useStyles = makeStyles(() => ({
  root: {
    '& .css-jq6zhv-MuiPaper-root-MuiDialog-paper': {
      borderRadius: '4px',
    },
  },
  deleteBtn: {
    color: '#AB3535',
    '&:hover': {
      background: 'transparent',
    },
  },
  cancelBtn: {
    color: '#6b778c',
    '&:hover': {
      background: 'transparent',
    },
  },
  loadingCircle: {
    color: '#DF1683',
    width: '20px !important',
    height: '20px !important',
    marginLeft: 5,
    marginRight: 5,
  },
}));
const RecruitmentsReadModel: FC<any> = (props) => {
  const classes = useStyles();
  const { clientsList, openDeleteModal, setOpenDeleteModal, setClientsList } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleModalClose = () => {
    setOpenDeleteModal(false);
  };

  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);

  const bulkDeActivateUser = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const res = await messagesApi.recruitmentsMarkAsRead(clientsList);
      console.log('ressss: ', res);
      if (res?.status === 201) {
        const msg = t('messages_bulk_read_message');
        toast.dismiss();
        toast.success(
          <div style={{ fontFamily: 'Axiforma-regular' }}>{msg}</div>,
          {
            duration: 10000,
          }
        );
        window.location.href = '/admin/recruitment-emails';
        setClientsList([]);
      }
      handleModalClose();
      setIsSubmitting(false);
    } catch (err) {
      // logger(err, 'error');
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog
      open={openDeleteModal}
      className={classes.root}
      onClose={handleModalClose}
    >
      <DialogTitle>
        <strong>Are you sure?</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You want to mark these record(s) as Read?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleModalClose}
          className={classes.cancelBtn}
        >
          No
        </Button>
        <Button
          onClick={bulkDeActivateUser}
          autoFocus
          className={classes.deleteBtn}
          disabled={isSubmitting}
        >
          Yes
          {isSubmitting && (
            <CircularProgress className={classes.loadingCircle} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RecruitmentsReadModel.propTypes = {
  clientsList: PropTypes.array,
  openDeleteModal: PropTypes.bool,
  setOpenDeleteModal: PropTypes.func,
};

RecruitmentsReadModel.defaultProps = {
  openDeleteModal: false,
};

export default RecruitmentsReadModel;
