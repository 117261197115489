import axios from 'src/utils/axios';
import parse from 'src/utils/parse';
import { isUndefined } from 'lodash';
import logger from 'src/utils/logger';
import i18next from 'i18next';
import pureAxios from 'axios';

class MessagesApi {
  async getAll(filter: any): Promise<any> {
    return new Promise((resolve, reject) => {
      pureAxios
        .post(
          `${process.env.REACT_APP_IT22_MESSAGES_EMAIL_USERS_URL}/email-service/getAllContacts`,
          filter
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  async getAllRecruitments(filter: any): Promise<any> {
    return new Promise((resolve, reject) => {
      pureAxios
        .post(
          `${process.env.REACT_APP_IT22_MESSAGES_EMAIL_USERS_URL}/email-service/getAllRecruitments`,
          filter
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  async recruitmentsMarkAsRead(messagesids: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Mark as read
      pureAxios
        .post(
          `${process.env.REACT_APP_IT22_MESSAGES_EMAIL_USERS_URL}/email-service/recruitersBulkRead`,
          {
            list: messagesids,
          }
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('Failed'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('Error'));
        });
    });
  }

  async markAsRead(messagesids: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Mark as read
      pureAxios
        .post(
          `${process.env.REACT_APP_IT22_MESSAGES_EMAIL_USERS_URL}/email-service/bulkRead`,
          {
            list: messagesids,
          }
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error('Failed'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('Error'));
        });
    });
  }

  async bulkDelete(messagesids: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Bulk Delete
      pureAxios
        .post(
          `${process.env.REACT_APP_IT22_MESSAGES_EMAIL_USERS_URL}/email-service/bulkDeactive`,
          {
            list: messagesids,
          }
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error('Failed'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('Error'));
        });
    });
  }

  async getById(messageId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get By Id Client
      axios
        .get(
          `${process.env.REACT_APP_IT22_MESSAGES_EMAIL_USERS_URL}/email-service/getById/${messageId}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error('Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('Failed'));
        });
    });
  }

  async recruitmentGetById(recruitmentId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get By Id Client
      axios
        .get(
          `${process.env.REACT_APP_IT22_MESSAGES_EMAIL_USERS_URL}/email-service/getRecruitmentsById/${recruitmentId}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error('Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('Failed'));
        });
    });
  }

  async getByEmail(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get By Email Client
      axios
        .get(
          `${process.env.REACT_APP_IT22_MESSAGES_EMAIL_USERS_URL}/users/email/${email}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error('Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('Failed'));
        });
    });
  }

  async unreadRecruitmentMessagesCount(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get By Id Client
      axios
        .get(
          `${process.env.REACT_APP_IT22_MESSAGES_EMAIL_USERS_URL}/email-service/getRecruitersUnReadercounter`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            console.log('data: ', response);
            resolve(response);
          } else {
            reject(new Error('Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          console.log(error);
          reject(error);
        });
    });
  }

  async unreadNotifications(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get By Id Client
      axios
        .get(
          `${process.env.REACT_APP_IT22_MESSAGES_EMAIL_USERS_URL}/email-service/unreadMessages`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error('Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('Failed'));
        });
    });
  }

  async allMessagesCounter(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get all Messages count
      axios
        .get(
          `${process.env.REACT_APP_IT22_MESSAGES_EMAIL_USERS_URL}/getAllUnRead`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error('Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('Failed'));
        });
    });
  }

  async replyBackEmail(filter: any): Promise<any> {
    return new Promise((resolve, reject) => {
      pureAxios
        .post(
          `${process.env.REACT_APP_IT22_MESSAGES_EMAIL_USERS_URL}/email-service`,
          filter
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }
}

export const messagesApi = new MessagesApi();
