import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import toast from 'react-hot-toast';
// import { clientApi } from 'src/api/clientApi';
// import { tagApi } from '../../../api/tagApi';
import { messagesApi } from 'src/api/messagesApi';

const useStyles = makeStyles(() => ({
  root: {
    '& .css-jq6zhv-MuiPaper-root-MuiDialog-paper': {
      borderRadius: '4px'
    }
  },
  deleteBtn: {
    color: '#AB3535',
    '&:hover': {
      background: 'transparent'
    }
  },
  cancelBtn: {
    color: '#6b778c',
    '&:hover': {
      background: 'transparent'
    }
  },
  loadingCircle: {
    color: '#DF1683',
    width: '20px !important',
    height: '20px !important',
    marginLeft: 5,
    marginRight: 5
  },
}));
const DeleteModal: FC<any> = (props) => {
  const classes = useStyles();
  const { clientsList, openDeleteModal, setOpenDeleteModal } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleModalClose = () => {
    setOpenDeleteModal(false);
  };
  const bulkDeActivateUser = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await messagesApi.bulkDelete(clientsList);
      const msg = `${clientsList?.length} Message(s) are deleted Successfully`;
      toast.dismiss();
      toast.success(<div style={{ fontFamily: 'Axiforma-regular' }}>{msg}</div>, {
        duration: 10000,
      });
      window.location.href = '/admin/messages';
    } catch (err) {
      // logger(err, 'error');
    }
  };

  return (
    <Dialog
      open={openDeleteModal}
      className={classes.root}
      onClose={handleModalClose}
    >
      <DialogTitle>
        <strong>Are you sure?</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You want to delete these Record(s)?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleModalClose}
          className={classes.cancelBtn}
        >
          No
        </Button>
        <Button
          onClick={bulkDeActivateUser}
          autoFocus
          className={classes.deleteBtn}
          disabled={isSubmitting}
        >
          Yes,Delete
          {isSubmitting && (<CircularProgress className={classes.loadingCircle} />)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteModal.propTypes = {
  clientsList: PropTypes.array,
  openDeleteModal: PropTypes.bool,
  setOpenDeleteModal: PropTypes.func,
};

DeleteModal.defaultProps = {
  openDeleteModal: false
};

export default DeleteModal;
