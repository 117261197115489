import { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography, Link } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { useTranslation } from 'react-i18next';
import { uuid } from 'uuidv4';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { messagesApi } from 'src/api/messagesApi';
import logger from 'src/utils/logger';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiButton-root': {
      borderRadius: '0px',
    },
    '& .css-1o8sry1-MuiList-roo': {
      borderRadius: '0px',
    },
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontFamily: 'Axiforma-regular',
    },
    '& .active': {
      background: '#C220271A',
    },
  },
  divider: {
    marginBottom: '1rem',
  },
  countersWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  counter: {
    padding: 4,
    position: 'relative',
    top: '-10px',
    marginBottom: 10,
    color: 'white',
    minWidth: 30,
    borderRadius: '50%',
    textAlign: 'center',
    paddingRight: 5,
    right: 18,
  },
  totalDevicesCounter: {
    color: 'rgb(89, 99, 95, 1) !important',
    backgroundColor: 'rgba(167,176,172,.2)',
  },
  onlineDevicesCounter: {
    color: 'rgb(76, 175, 80) !important',
    backgroundColor: 'rgb(232, 245, 233)',
  },
  offlineDevicesCounter: {
    color: 'rgb(255, 87, 86) !important',
    backgroundColor: 'rgba(255, 87, 86, .2)',
  },
  errorDevicesCounter: {
    color: 'rgba(246, 165, 16, 1) !important',
    backgroundColor: 'rgba(246, 165, 16, .2)',
  },
  profileWrapper: {
    width: 215,
    margin: '15px auto 0',
    backgroundColor: 'white',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
      '& $editButton': {
        display: 'flex',
        width: '100%',
        height: '100%',
        color: '#121037',
        borderRadius: 0,
        backgroundColor: 'rgba(255, 255, 255, .75)',
        [theme.breakpoints.down('sm')]: {
          width: '50% !important',
        },
      },
      '& $deleteButton': {
        display: 'flex',
        color: '#DD0000',
        width: '100%',
        borderRadius: 0,
        backgroundColor: 'rgba(255, 255, 255, .75)',
        [theme.breakpoints.down('sm')]: {
          width: '50% !important',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  profileImage: {
    width: 92,
    height: 92,
    margin: '0 auto',
    display: 'flex',
    borderRadius: '50%',
    border: '1px solid #f9f8f8',
    objectFit: 'fill',
  },
  profileButtonWrapper: {
    position: 'absolute',
    top: 0,
    left: '29%',
    right: '50%',
    bottom: 0,
    height: 92,
    width: 92,
    opacity: 0,
    transition: '.5s ease',
    backgroundColor: 'rgba(255, 255, 255, .75)',
    '&:hover': {
      opacity: 0.5,
    },
  },
  editButton: {
    display: 'none',
  },
  ss: {
    fontSize: '14px',
    fontFamily: 'Axiforma-regular'
  },
  deleteButton: {
    display: 'none',
  },
  buttonText: {
    marginLeft: 10,
    textTransform: 'capitalize',
    fontSize: '.8rem',
  },
  deleteText: {
    color: '#DD0000',
    fontSize: '.8rem',
  },
  userName: {
    textAlign: 'center',
  },
  loadingWrapper: {
    marginTop: 20,
    textAlign: 'center',
  },
  loadingCircle: {
    color: theme.palette.primary.main,
    width: '20px !important',
    height: '20px !important',
    marginLeft: 5,
    marginRight: 5
  },
  hideNavSection: {
    display: 'none',
  }
}));

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const classes = useStyles();
  const location = useLocation();
  // const [profileImage, setProfileImage] = useState<string>('');
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);
  // const { user } = useAuth();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [messagesCounter, setMessagesCounter] = useState<number>(null);
  const [recruitmentsCounter, setRecruitmentsCounter] = useState<number>(null);
  // const decoded: any = jwtDecode(localStorage.getItem('accessToken'));

  // const handlePicture = async (e) => {
  //   try {
  //     setIsSubmitting(true);
  //     const image = await userApi.uploadFile(e.target.files[0], 'user', profileImage);
  //     setProfileImage(image?.fileName);
  //     localStorage.setItem('profile_image', image?.fileName);
  //     await userApi.updateProfileImageByKeyCloakId(decoded?.sub, image?.fileName);
  //     setIsSubmitting(false);
  //     const msg = t('image_upload_success');
  //     toast.dismiss();
  //    toast.success(<div style={{ fontFamily: 'Axiforma-regular' }}>{msg}</div>, {
  //       duration: 10000,
  //     });
  //   } catch (err) {
  //     setIsSubmitting(false);
  //     logger(err, 'error');
  //     const errorMsg = err?.response?.status === 400 ? t('image_upload_file_type_error') : t('image_upload_file_size_error');
  //     toast.dismiss();
  //     toast.error(errorMsg, {
  //       duration: 10000,
  //     });
  //   }
  // };

  // const getUserByKeyCloakId = async () => {
  //   const keyCloakUser = await userApi.getByKeycloakId(decoded?.sub);
  //   localStorage.setItem('profile_image', keyCloakUser?.profile_image);
  //   setProfileImage(keyCloakUser?.profile_image);
  // };

  // useEffect(() => {
  //   if (localStorage.getItem('profile_image')) {
  //     setProfileImage(localStorage.getItem('profile_image'));
  //   } else {
  //     getUserByKeyCloakId();
  //   }
  // }, []);

  // const ref = useRef(null);

  // const handleFileWindow = () => {
  //   const element: HTMLElement = document.getElementById('profilePicture') as HTMLElement;
  //   element.click();
  // };

  const unreadMessagesCounter = async () => {
    try {
      const res = await messagesApi.unreadNotifications();
      setMessagesCounter(res[0]?.messages_count);
    } catch (err) {
      logger(err);
      setMessagesCounter(null);
    }
  };
  const unreadRecruitmentMessages = async () => {
    try {
      const res = await messagesApi?.unreadRecruitmentMessagesCount();
      console.log('unread: ', res);
      setRecruitmentsCounter(res?.data?.messages_count || null);
      console.log('res: ', res);
    } catch (error) {
      logger(error);
      setRecruitmentsCounter(null);
    }
  };
  useEffect(() => {
    unreadMessagesCounter();
    unreadRecruitmentMessages();
    setInterval(() => {
      unreadRecruitmentMessages();
      unreadMessagesCounter();
    }, 15000);
  }, []);
  const sections = [
    {
      items: [
        {
          title: t('sidebar_users_overview'),
          path: '/admin/users',
          icon: <img
            src="https://assets.it22.nl/it22/homepage/users.svg"
            alt="Users"
          />,
          viewable: true,
        },
      ]
    },
    {
      items: [
        {
          title: t('sidebar_messages_overview'),
          path: '/admin/messages',
          icon: <img
            src="https://assets.it22.nl/it22/homepage/shopping-cart.svg"
            alt="Messages"
          />,
          viewable: true,
          counter: messagesCounter
        },
      ]
    },
    {
      items: [
        {
          title: t('sidebar_recruitment_messages_overview'),
          path: '/admin/recruitment-emails',
          icon: <img
            src="https://assets.it22.nl/it22/homepage/shopping-cart.svg"
            alt="Messages"
          />,
          viewable: true,
          counter: recruitmentsCounter
        },
      ]
    },
    {
      items: [
        {
          title: t('sidebar_blogs_overview'),
          path: '/admin/blogs',
          icon: <img
            src="https://assets.it22.nl/it22/homepage/blog.svg"
            alt="Blog"
          />,
          viewable: true,
        },
      ]
    },
    {
      items: [
        {
          title: 'Blog Categories',
          path: '/admin/categories',
          icon: <img
            src="https://assets.it22.nl/it22/homepage/categories.svg"
            alt="Categories"
          />,
          viewable: true,
        },
      ]
    },
    {
      items: [
        {
          title: 'Tags',
          path: '/admin/tags',
          icon: <img
            src="https://assets.it22.nl/it22/homepage/tag.svg"
            alt="Tag"
          />,
          viewable: true,
        },
      ]
    },
  ];
  const settings = [
    {
      items: [
        {
          title: t('sidebar_setting'),
          path: '/admin/setting',
          icon: <img
            src="https://assets.it22.nl/it22/homepage/settings.svg"
            alt="Dashboard"
          />,
          viewable: true,
        },
      ]
    },
  ];
  const dashboards = [
    {
      items: [
        {
          title: t('sidebar_dashboard'),
          path: '/dashboard',
          icon: <img
            src="https://assets.it22.nl/it22/homepage/layout.svg"
            alt="Dashboard"
          />,
          viewable: true,
        },
      ]
    },
  ];
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const content = (
    <Box
      className={classes.root}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 1,
        pt: 0,
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 1,
            pt: 0,
            pb: 0,
            // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Logo
            sx={{
              height: 40,
              width: 40
            }}
          />
        </Box>
        {/* <Box>
          <form>
            <Box
              className={classes.profileWrapper}
              component="div"
            >
              {isSubmitting ? (
                <div
                  className={classes.loadingWrapper}
                >
                  {t('image_uploading_text')}
                  &nbsp;&nbsp;
                  <CircularProgress className={classes.loadingCircle} />
                </div>
              ) : (
                <>
                  <img
                    className={classes.profileImage}
                    src={profileImage === 'dummy_image.png' ? `https://assets.it22.nl/fortes-energy/admin/${profileImage}` : `https://uploadimages.fortes.it22.cloud/images/user/${profileImage}`}
                    alt="Default Avatar"
                  />
                  <Box
                    className={classes.profileButtonWrapper}
                    component="div"
                    onClick={handleFileWindow}
                  >
                    <Button className={classes.editButton}>
                      <>
                        <input
                          ref={ref}
                          accept="image/jpg,image/jpeg,image/png,.pdf,.doc,.docx"
                          type="file"
                          id="profilePicture"
                          name="file"
                          onChange={handlePicture}
                          hidden
                        />
                        <div style={{ display: 'flex' }}>
                          <svg
                            style={{
                              position: 'relative',
                              top: 2,
                              width: 13,
                            }}
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.2968 8.65963C13.9005 8.65963 13.5801 8.98074 13.5801 9.37626V15.1096C13.5801 15.5045 13.259 15.8262 12.8635 15.8262H2.83017C2.43453 15.8262 2.11355 15.5045 2.11355 15.1096V5.07626C2.11355 4.68141 2.43453 4.35964 2.83017 4.35964H8.56354C8.95984 4.35964 9.28016 4.03854 9.28016 3.64302C9.28016 3.24738 8.95984 2.92627 8.56354 2.92627H2.83017C1.64481 2.92627 0.680176 3.89091 0.680176 5.07626V15.1096C0.680176 16.295 1.64481 17.2596 2.83017 17.2596H12.8635C14.0489 17.2596 15.0135 16.295 15.0135 15.1096V9.37626C15.0135 8.97996 14.6931 8.65963 14.2968 8.65963Z"
                              fill="#000"
                            />
                            <path
                              d="M7.39915 8.00667C7.34902 8.0568 7.31529 8.12058 7.30099 8.18934L6.79433 10.7236C6.77071 10.841 6.80798 10.9621 6.89249 11.0474C6.96059 11.1155 7.05232 11.152 7.14628 11.152C7.16911 11.152 7.19286 11.1499 7.21648 11.1449L9.74992 10.6383C9.82012 10.6238 9.8839 10.5902 9.93337 10.54L15.6036 4.86973L13.0702 2.33643L7.39915 8.00667Z"
                              fill="#000"
                            />
                            <path
                              d="M17.3556 0.58414C16.6569 -0.114635 15.5202 -0.114635 14.8221 0.58414L13.8303 1.57594L16.3638 4.10938L17.3556 3.11744C17.6939 2.77993 17.8802 2.32983 17.8802 1.85112C17.8802 1.37241 17.6939 0.922307 17.3556 0.58414Z"
                              fill="#000"
                            />
                          </svg>
                        </div>
                      </>
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </form>
          <Box
            mt={2}
            mb={1}
          >
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.userName}
            >
              {user.name}
            </Typography>
          </Box>
        </Box> */}
        <div className={classes.divider} />
        <Box>
          <Typography
            color="textSecondary"
            variant="subtitle1"
          >
            REPORTS
          </Typography>
          {dashboards.map((section) => (
            <NavSection
              key={uuid()}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 0
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Box>
          <Typography
            color="textSecondary"
            variant="subtitle1"
          >
            MANAGEMENT
          </Typography>
          {sections.map((section) => (
            <NavSection
              key={uuid()}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 0
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Box>
          <Typography
            color="textSecondary"
            variant="subtitle1"
          >
            SETTINGS
          </Typography>
          {settings.map((section) => (
            <NavSection
              key={uuid()}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 0
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider className={classes.divider} />
        <Box>
          <Box className={classes.countersWrapper}>
            <Link
              color="inherit"
              component={RouterLink}
              to="/admin/ChangeLogs"
              variant="subtitle2"
            // className={classes.linkButton}
            >
              <Typography
                color="textSecondary"
                variant="subtitle2"
                sx={{
                  pb: 1
                }}
              >
                {t('sidebar_change_logs')}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        className={classes.root}
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: '100% !important',
            top: '0 !important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      className={classes.root}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
