/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable @typescript-eslint/indent */
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// import { Link as RouterLink } from 'react-router-dom';
import useSettings from '../../hooks/useSettings';
import gtm from '../../lib/gtm';
// import AddIcon from 'src/icons/Add';
import { useTranslation } from 'react-i18next';
// import jwtDecode from 'jwt-decode';
import RecruitmentMessagesListTable from 'src/components/messages/RecruitmentMessagesListTable';
import logger from 'src/utils/logger';

const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
        marginBottom: '26px'
    },
    addNewClient: {
        textTransform: 'uppercase',
        borderRadius: 4,
        border: `1px solid ${theme.palette.secondary.main}`,
        marginBottom: theme.spacing(1),
        '& + &': {
            marginLeft: theme.spacing(1)
        },
        '&:hover': {
            color: theme.palette.secondary.main,
            background: theme.palette.background.default,
            '& path': {
                fill: theme.palette.secondary.main,
            },
        }
    },
    text: {
        fontFamily: 'Axiforma-regular',
        fontSize: '14px',
        fontWeight: 500,
        fontStyle: 'normal',
        color: 'white'
    },
    buttonWrapperLink: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
}));
const RecruitmentListView: FC = () => {
    const classes = useStyles();
    const { settings } = useSettings();
    const locale = localStorage.getItem('locale');
    const { t } = useTranslation(locale);

    logger(t);
    useEffect(() => {
        gtm.push({ event: 'page_view' });
    }, []);

    return (
        <>
            <Helmet>
                <title>Recruitment Messages</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    pt: 4,
                    pb: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                        className={classes.container}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                Recruitment Messages
                            </Typography>
                        </Grid>
                    </Grid>
                    <RecruitmentMessagesListTable />
                </Container>
            </Box>
        </>
    );
};

export default RecruitmentListView;
