// import type { Blog, BlogsList } from 'src/types/blog';

// import axios from 'src/utils/axios';
import parse from 'src/utils/parse';
import { isUndefined } from 'lodash';
import logger from 'src/utils/logger';
// import pureAxios from 'axios';
import axios from 'src/utils/axios';

class AdminUserApi {
  //   async create(blog: Blog): Promise<Blog> {
  //     return new Promise((resolve, reject) => {
  //       delete blog?.submit;
  //       // Create Client
  //       axios
  //         .post(`${process.env.REACT_APP_IT22_MESSAGES_URL}`, blog)
  //         .then((response) => {
  //           response = parse(response);
  //           if (!isUndefined(response?.data)) {
  //             const data = response?.data;
  //             resolve(data);
  //           }
  //         })
  //         .catch((err) => {
  //           logger(err, 'error');
  //           reject(err);
  //         });
  //     });
  //   }
  // ${'?'}${'pageSize'}${'='}${
  //   filter?.pageSize
  // }${'$'}${'pageNo'}${'='}${filter?.pageNo}${'$'}${'serach'}${'='}${
  //   filter?.serach
  // }
  //   async getAll(filter: any): Promise<any> {
  //     return new Promise((resolve, reject) => {
  //       axios
  //         .get(
  //           // eslint-disable-next-line operator-linebreak
  //           `${process.env.REACT_APP_IT22_MESSAGES_URL}/message?${filter}
  //           `
  //           // {
  //           //   headers: {
  //           //     'Access-Control-Allow-Origin': '*',
  //           //   },
  //           // }
  //         )
  //         .then((response) => {
  //           response = parse(response);
  //           if (!isUndefined(response?.data)) {
  //             const res = response?.data;
  //             resolve(res);
  //           } else {
  //             reject(new Error('Failed'));
  //           }
  //         })
  //         .catch((error) => {
  //           logger(error, 'error');
  //           reject(new Error('Error'));
  //         });
  //     });
  //   }

  // async markAsRead(messageId: string): Promise<any> {
  //   const id = Number(messageId);
  //   return new Promise((resolve, reject) => {
  //     // De-active Client
  //     axios
  //       .patch(
  //         `${process.env.REACT_APP_IT22_MESSAGES_URL}/message/status/${id}`,
  //         {
  //           is_read: true,
  //         }
  //       )
  //       .then((response) => {
  //         response = parse(response);
  //         if (!isUndefined(response?.data)) {
  //           const data = response?.data;
  //           resolve(data);
  //         } else {
  //           reject(new Error('Failed'));
  //         }
  //       })
  //       .catch((error) => {
  //         logger(error, 'error');
  //         reject(new Error('Error'));
  //       });
  //   });
  // }

  async getById(keyClockId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get By Id Client
      axios
        .get(
          `${process.env.REACT_APP_IT22_ADMIN_SETTINGS_URL}/user/${keyClockId}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error('Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('Failed'));
        });
    });
  }

  async edit(userId: string, userData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.REACT_APP_IT22_ADMIN_SETTINGS_URL}/user/${userId}`,
          userData
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          }
        })
        .catch((err) => {
          logger(err, 'error');
          reject(err);
        });
    });
  }

  async updateUserPassword(userId: string, userData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.REACT_APP_IT22_ADMIN_SETTINGS_URL}/user/updateuser/${userId}`,
          userData
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          }
        })
        .catch((err) => {
          logger(err, 'error');
          reject(err);
        });
    });
  }
  //   async uploadFile(
  //     event: string,
  //     imageType: string,
  //     previousImage: string = ''
  //   ): Promise<any> {
  //     // Reset Password
  //     return new Promise((resolve, reject) => {
  //       const formData = new FormData();
  //       formData.append('file', event);
  //       formData.append('type', imageType);
  //       if (previousImage?.length > 0 && previousImage !== 'dummy_image.png') {
  //         formData.append('previous_image', previousImage);
  //       }
  //       logger(previousImage);
  //       axios
  //         .post('https://be-upload.apps.alpha.it22.cloud/upload', formData)
  //         .then((response) => {
  //           response = parse(response);
  //           if (!isUndefined(response)) {
  //             const res = response?.data;
  //             resolve(res);
  //           } else {
  //             reject(new Error('Error uploading image'));
  //           }
  //         })
  //         .catch((error) => {
  //           logger(error, 'error');
  //           reject(error);
  //         });
  //     });
  //   }
  /*
    async getAllClientAutoComplete(search: string): Promise<Client[]> {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_CLIENTS_URL}/getAllClientAutoComplete`, {
          search
        }).then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response?.data?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        }).catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
      });
    }

    async getAll(page: number, limit: number, currentTab: string, search: string, sortColumn: any, sortDirection: boolean): Promise<ClientOverviewProps> {
      return new Promise((resolve, reject) => {
        // Get By Id Client

        let sortList = {};
        if (sortColumn.name) {
          sortList = {
            'client.name': sortDirection ? 'ASC' : 'DESC',
            'client.email': sortDirection ? 'ASC' : 'DESC',
          };
        }

        if (sortColumn.address) {
          sortList = {
            'client.city': sortDirection ? 'ASC' : 'DESC',
            'client.country': sortDirection ? 'ASC' : 'DESC',
          };
        }

        if (sortColumn.is_activated) {
          sortList = {
            'client.is_activated': sortDirection ? 'ASC' : 'DESC',
          };
        }

        if (sortColumn.created_at) {
          sortList = {
            'client.created_at': sortDirection ? 'ASC' : 'DESC',
          };
        }

        if (sortColumn.total_devices) {
          sortList = {
            deviceTotalCount: sortDirection ? 'ASC' : 'DESC',
          };
        }

        const filter: any = {
          page,
          limit,
          sortList
        };

        let data: any;

        if (Object.keys(sortList)?.length === 0) {
          delete filter.sortList;
        }

        if (search?.length > 0) {
          data = {
            Filter: filter,
            search
          };
        } else {
          data = {
            Filter: filter,
          };
        }

        if (!isUndefined(currentTab)) {
          if (+currentTab !== -1) {
            filter.active = !!currentTab;
          }
        }

        axios.post(`${process.env.REACT_APP_CLIENTS_URL}/getAllClient`, data).then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        }).catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
      });
    }

    async getById(clientId: number): Promise<Client> {
      return new Promise((resolve, reject) => {
        // Get By Id Client
        axios.get(`${process.env.REACT_APP_CLIENTS_URL}/${clientId}`).then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        }).catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
      });
    }

    async deActivate(clientId: string, isActive: number): Promise<Client> {
      return new Promise((resolve, reject) => {
        // De-active Client
        axios.patch(`${process.env.REACT_APP_CLIENTS_URL}/delete/${clientId}`, {
          is_activated: !isActive
        }).then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        }).catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
      });
    }

    async bulkDeActivate(clientIds: string[]): Promise<Client> {
      return new Promise((resolve, reject) => {
        // Bulk De-active Clients
        axios.post(`${process.env.REACT_APP_CLIENTS_URL}/bulk_deactive`, {
          list: clientIds
        }).then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        }).catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
      });
    }

    async updateClientLogo(clientId: string, logo: string, allowCustomization: boolean): Promise < any > {
      // Update Profile Image By Keycloak Id
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.REACT_APP_CLIENTS_URL}/updateClientLogo/${clientId}`, {
          logo,
          allow_customization: allowCustomization
        }).then((response) => {
          response = parse(response);
          if (!isUndefined(response)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        }).catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
      });
    }

    async getApplicationKeyList(client: Client): Promise<any> {
      // Get Application Key List
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_CLIENTS_URL}/applicationKeyList?application_id=${client?.application_id}&account_id=${client?.account_id}`).then((response) => {
          response = parse(response);
          if (!isUndefined(response)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        }).catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
      });
    }

    async getApplicationById(client: Client): Promise<any> {
      // Get Application By Id
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_CLIENTS_URL}/application/applicationRead?application_id=${client?.application_id}&account_id=${client?.account_id}`).then((response) => {
          response = parse(response);
          if (!isUndefined(response)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        }).catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
      });
    }

    async applicationSuspend(client: Client): Promise<any> {
      // Application Suspend
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.REACT_APP_CLIENTS_URL}/application/applicationSuspend?application_id=${client?.application_id}&account_id=${client?.account_id}`).then((response) => {
          response = parse(response);
          if (!isUndefined(response)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        }).catch((error) => {
          logger(error, 'error');
          reject(error);
        });
      });
    }

    async applicationResume(client: Client): Promise<any> {
      // Application Resume
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.REACT_APP_CLIENTS_URL}/application/applicationResume?application_id=${client?.application_id}&account_id=${client?.account_id}`).then((response) => {
          response = parse(response);
          if (!isUndefined(response)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        }).catch((error) => {
          logger(error, 'error');
          reject(error);
        });
      });
    }
    */
}

export const adminUserApi = new AdminUserApi();
