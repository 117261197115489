/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  useRef, useState, useEffect
} from 'react';
import type { FC } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LogoutIcon from '@mui/icons-material/Logout';
import { messagesApi } from 'src/api/messagesApi';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Popover,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableBody
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';
import { adminUserApi } from 'src/api/adminUserApi';
import logger from 'src/utils/logger';
// import parametersSerialize from 'src/utils/parametersSerialize';
import { isUndefined } from 'lodash';
import notificationEllipse from 'src/utils/notificationEllipse';

const useStyles = makeStyles(() => ({
  topBarFlex: {
    display: 'flex'
  },
  alignUserNameCenter: {
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  }
}));

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout } = useAuth();
  const [messages, setMessages] = useState<any>();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>([]);
  const [notificationCounter, setNoticationCounter] = useState<number>(null);

  const classes = useStyles();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      window.location.href = '/';
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  const handleMessagesHandler = () => {
    window.location.href = 'admin/messages';
  };
  const getAll = async () => {
    try {
      const filter = {
        page: 0,
        limit: 5,
        orderBy: {
          created_at: 'desc'
        },
        is_activated: 1,
      };
      const res = await messagesApi.getAll(filter);
      setMessages(res?.data);
    } catch (err) {
      logger(err, 'error');
    }
  };

  useEffect(() => {
    getAll();
    setInterval(() => {
      getAll();
    }, 15000);
  }, []);
  const getAdminUserById = async () => {
    try {
      if (user?.user_id !== null && user.user_id !== undefined) {
        const res = await adminUserApi.getById(user.user_id);
        setUserData(res?.data);
      }
    } catch (err) {
      logger(err);
    }
  };
  useEffect(() => {
    getAdminUserById();
  }, [user]);

  const unreadMessagesCounter = async () => {
    try {
      const res = await messagesApi.unreadNotifications();
      setNoticationCounter(res[0]?.messages_count);
    } catch (err) {
      logger(err);
    }
  };
  useEffect(() => {
    unreadMessagesCounter();
    setInterval(() => {
      unreadMessagesCounter();
    }, 15000);
  }, []);
  const navigateToOneMessage = (e, messageId: string): void => {
    if (isUndefined(e.target.value)) {
      navigate(`/admin/messages/singleMessage/${messageId}`);
      setOpen(false);
    }
  };
  return (
    <>
      <div className={classes.topBarFlex}>
        <div className={classes.alignUserNameCenter}>
          <Box
            component={ButtonBase}
            ref={anchorRef}
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Avatar
              style={{ objectFit: 'contain' }}
              src={`${process.env.REACT_APP_CLOUD_IMAGES_URL}/images/user/${userData?.profile_image}`}
              sx={{
                height: 32,
                width: 32
              }}
            />
          </Box>
          <Box
            sx={{ ml: 1 }}
            style={{ fontFamily: 'Axiforma-regular', fontSize: '14px' }}
          >
            {`${user.name}`}
          </Box>
        </div>
        <Box sx={{ ml: 2 }}>
          {notificationCounter > 0 ? (
            <img
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              src="https://assets.it22.nl/it22/header/notifications.png"
              alt=""
              onClick={handleOpen}
            />
          ) : <NotificationsNoneIcon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={handleOpen} />}

        </Box>
        <Box sx={{ ml: 2, mr: 2 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LogoutIcon onClick={handleLogout} />
        </Box>
        <Popover
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom'
          }}
          keepMounted
          onClose={handleClose}
          // eslint-disable-next-line no-restricted-globals
          open={open}
          PaperProps={{
            sx: { width: 330, borderRadius: '4px !important' }
          }}
        >
          <Box sx={{ p: 2, mr: 4 }}>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              style={{ fontSize: '16px' }}
            >
              <b>Notifications</b>
            </Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Table>
              <TableBody>
                {messages?.map((message) => (
                  <TableRow
                    hover
                    key={message?.id}
                    onClick={(e) => navigateToOneMessage(e, message?.id)}
                    style={{ backgroundColor: message?.is_read ? 'inherit' : 'rgba(194, 32, 39, 0.1)', borderLeft: message?.is_read ? '' : '4px solid #C22027' }}
                  >
                    <TableCell style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ marginRight: '3%' }}>{message?.firstName}</div>
                          <div>{message?.lastName}</div>
                        </div>
                        <div style={{ color: '#A6B1BB' }}>
                          {message?.datetime}
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        {notificationEllipse(message?.message)}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                {messages?.length === 0 && (
                  <TableRow
                    key="aa-12-2d-ac-xza"
                  >
                    <TableCell
                      colSpan={6}
                    // className={classes.noRecordFound}
                    >
                      No record(s) found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
          <Divider />
          <Box sx={{ p: 1 }}>
            <Button
              color="primary"
              fullWidth
              style={{ fontSize: '16px' }}
              onClick={handleMessagesHandler}
            // onClick={handleLogout}
            // variant="outlined"
            // className={classes.logout}
            >
              View Older
            </Button>
          </Box>
        </Popover>
      </div>
    </>
  );
};

export default AccountPopover;
